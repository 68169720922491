import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaBullhorn, FaTractor, FaUserFriends, FaWallet, FaParachuteBox } from 'react-icons/fa';
import './BottomNavbar.css'; // Add some styles
import TaskCenterIcon from '../../admin/icons/TaskIcon';
import NewsIcon from '../../admin/icons/NewsIcon';

const BottomNav = () => {
  return (
    <div className='navbar-container'>
    <div className="bottom-nav">
      <NavLink to="/" activeClassName="active2">
        <FaTractor />
        <span>Farm</span>
      </NavLink>
      <NavLink to="/tasks" activeClassName="active2">
        <TaskCenterIcon />
        <span>Earn</span>
      </NavLink>
      <NavLink to="/referral" activeClassName="active2">
        <FaUserFriends />
        <span>Refer</span>
      </NavLink>
      <NavLink to="/airdrop" activeClassName="active2">
        <FaParachuteBox />
        <span>Airdrop</span>
      </NavLink>
    
    </div>
    </div>
  );
};

export default BottomNav;
