import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, db } from '../../components/utils/Firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, updateDoc, getDocs, collection, query, where, increment } from 'firebase/firestore';
import { useAuth } from '../../hooks/Context';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../utils/Loader'; // Import the Loader component
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA component

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [captchaVerified, setCaptchaVerified] = useState(false); // Add state for CAPTCHA verification
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const generateRandomUsername = () => {
    const randomChars = uuidv4().slice(0, 4);
    return `Hexauser${randomChars}`;
  };

  const generateRandomId = () => {
    return uuidv4().slice(0, 7);
  };

  const generateReferralLink = (userId) => {
    return `https://hexalink.com/referral/${userId}`;
  };

  const generateRandomString = (length) => {
    return Math.random().toString(36).substr(2, length).toUpperCase();
  };

  const saveUserData = async (user) => {
    const userId = generateRandomId();
    const referralLink = generateReferralLink(userId);
    const randomString = generateRandomString(5);

    const userData = {
      email: user.email,
      username: generateRandomUsername(),
      balance: 10,
      fundingBalance:0,
      id: userId,
      referralCount: 0,
      referralCode: randomString,
      isFarming: false,
  
    };

    try {
      await setDoc(doc(db, 'users', user.uid), userData);

      if (referralCode) {
        const referrerQuery = query(collection(db, 'users'), where('referralCode', '==', referralCode));
        const querySnapshot = await getDocs(referrerQuery);

        if (!querySnapshot.empty) {
          const referrerDoc = querySnapshot.docs[0];
          await updateDoc(referrerDoc.ref, {
            referralCount: increment(1),
            balance: increment(10)
          });
        }
      }
    } catch (error) {
      console.error('Error saving user data: ', error);
    }
  };

  const firebaseErrorMessages = {
    'auth/email-already-in-use': 'This email is already in use.',
    'auth/invalid-email': 'Invalid email address.',
    'auth/operation-not-allowed': 'Operation not allowed.',
    'auth/weak-password': 'Password is too weak.',
    // Add more error codes and messages as needed
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    if (!captchaVerified) {
      setError("Please complete the CAPTCHA verification.");
      return;
    }

    setIsLoading(true); // Set loading state to true when sign-up starts

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user); // Send verification email
      await saveUserData(userCredential.user);
      setError("A verification email has been sent to your email address. Please verify your email to log in.");
      // navigate('/'); // Navigate to home page
    } catch (error) {
      const errorMessage = firebaseErrorMessages[error.code] || 'An error occurred. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false); // Set loading state to false after sign-up attempt
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // Update captchaVerified state based on CAPTCHA completion
  };

  if (currentUser) {
    navigate('/');
  }

  return (
    <div className="signup">

      {isLoading ? ( // Conditionally render the loader or the form
        <Loader /> // Show loader when loading state is true
      ) : (
        <form onSubmit={handleSignUp}>
                <h2>Sign Up</h2>
          <div className="mb-3">
            <input 
              type="email" 
              className="form-control"
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Email" 
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="password" 
              className="form-control"
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="password" 
              className="form-control"
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)} 
              placeholder="Confirm Password" 
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="text" 
              className="form-control"
              value={referralCode} 
              onChange={(e) => setReferralCode(e.target.value)} 
              placeholder="Referral Code (optional)" 
            />
          </div>
          <ReCAPTCHA
            sitekey="6LeUPgsqAAAAAJItZjayC_d0YsB4dYXljbV_EpHf" // Replace with your actual reCAPTCHA site key
            onChange={handleCaptchaChange}
          />
          <button type="submit" style={{width:"100%", margin:"0 auto", display:"block"}}>Sign Up</button>
          <span> Already have an account? <i> <Link to={`/sign-in`}>Log in</Link></i></span>
        </form>
      )}
      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
}

export default SignUp;
