import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const contractStandards = [
  {
    title: 'HexaLink Smart Contract Standard (HSCS)',
    description: 'The HexaLink Smart Contract Standard (HSCS) is designed to provide a uniform framework for developing and deploying smart contracts on the HexaLink blockchain. HSCS ensures compatibility, security, and ease of integration with DeFi and GameFi applications.',
    features: [
      'Standardized Interface: HSCS defines a common interface for smart contracts, making it easier for developers to create interoperable dApps.',
      'Security Best Practices: Includes built-in security features to prevent common vulnerabilities and ensure robust contract execution.',
      
    ],
    image: '/images/hscc.png',
  },
  {
    title: 'Interoperable Contract Framework (ICF)',
    description: 'The Interoperable Contract Framework (ICF) allows smart contracts to interact with other blockchain networks and ecosystems. It facilitates cross-chain functionality, enabling HexaLink smart contracts to communicate and exchange assets with contracts on different chains.',
    features: [
      'Cross-Chain Communication: Supports message passing and asset transfers between HexaLink and other blockchains.',
      'Decentralized Bridges: Utilizes decentralized bridges to ensure secure and reliable cross-chain interactions.',
      'Modular Design: Allows for easy integration with various blockchain protocols and standards.',
    ],
    image: '/images/icf.png',
  },
  {
    title: 'GameFi Integration Protocol (GFIP)',
    description: 'The GameFi Integration Protocol (GFIP) is tailored for GameFi applications, providing specific features for in-game assets, rewards, and interactions. GFIP streamlines the development of blockchain-based games by offering specialized tools and standards.',
    features: [
      'In-Game Asset Management: Facilitates the creation, transfer, and management of in-game assets on the blockchain.',
      'Reward Systems: Supports the implementation of reward systems and staking mechanisms for gaming achievements.',
      'Customizable Tokens: Allows for the creation of custom tokens and assets unique to GameFi applications.',
    ],
    image: '/images/gfip.png',
  },
  {
    title: 'Governance Contract Framework (GCF)',
    description: 'The Governance Contract Framework (GCF) provides a standard for implementing decentralized governance mechanisms within smart contracts. It supports voting, proposal management, and stakeholder engagement in decision-making processes.',
    features: [
      'Voting Mechanisms: Includes various voting models such as quadratic voting and delegated voting.',
      'Proposal Management: Facilitates the submission, review, and approval of governance proposals.',
      'Stakeholder Interaction: Enables active participation of stakeholders in governance decisions.',
    ],
    image: '/images/gcf.png',
  },
];

const SmartContractsPage = () => {
  return (
    <Container className="my-5">
      <br />
      <h1 className="text-center mb-4">Smart Contracts on HexaLink</h1>
      <Row>
        {contractStandards.map((standard, index) => (
          <Col md={6} key={index} className="mb-4">
            <Card className="card-dark">
        
              <Card.Body>
                <Card.Title>{standard.title}</Card.Title>
                <Card.Text>{standard.description}</Card.Text>
                <ul>
                  {standard.features.map((feature, i) => (
                    <li style={{padding:'10px 0'}} key={i}>{feature}</li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SmartContractsPage;
