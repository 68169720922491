// src/components/Auth/LogoutButton.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../utils/Firebase';
import { useAuth } from '../../hooks/Context';

const LogoutButton = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Navigate to sign-in page after logout
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  if (!currentUser) {
    return null; // If user is not authenticated, don't render the button
  }

  return (
    <button onClick={handleLogout} className="logout-btn">
      Logout
    </button>
  );
};

export default LogoutButton;
