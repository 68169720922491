import React, { useState } from 'react';


function ContactUs() {
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = `Message:\n${message}`;
    window.location.href = `mailto:support@hexalink.org?body=${encodeURIComponent(body)}`;
  };

  return (
    <div className="contact">
        <h3>Contact</h3>
      <form className='sub' onSubmit={handleSubmit}>
        <div className='sub-textarea'>
       
          <textarea
            name="message"
            value={message}
            onChange={handleChange}
            placeholder='send a message'
            required
            className='message'
          />
        </div>
        <div className='sub-button'>
        <button className='msg' type="submit">Subscribe</button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
