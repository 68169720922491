import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import GetStarted from './GetStarted';

import defiimg from '../assets/images/Layer 13_044241.png'

const defiServices = [
  {
    title: 'Liquidity Pools',
    description: 'HexaLink supports decentralized liquidity pools where users can provide liquidity in exchange for rewards. Liquidity providers earn a share of transaction fees and can participate in various liquidity farming opportunities.',
    image: require('../assets/images/Layer 5_044256.png'),
  },{
    title: 'Automated Market Makers (AMMs)',
    description: 'AMMs on HexaLink facilitate trading by using algorithms to determine token prices based on supply and demand. This ensures liquidity and efficient market operations without traditional order books.',
    image: require('../assets/images/Layer 4_044258.png'),
  },

  {
    title: 'Lending and Borrowing',
    description: 'HexaLink provides decentralized lending and borrowing services, allowing users to lend their assets for interest or borrow assets by providing collateral. This feature enhances liquidity and offers flexible financial services.',
    image: require('../assets/images/Layer 6_044253.png'),
  },
  {
    title: 'Yield Farming',
    description: 'Users can engage in yield farming by providing liquidity to various pools and earning rewards in the form of additional tokens. Yield farming optimizes returns on investments and supports the DeFi ecosystem.',
    image: require('../assets/images/Layer 11_044249.png'),
  },
  {
    title: 'Decentralized Exchanges (DEXs)',
    description: 'HexaLink integrates with decentralized exchanges, allowing users to trade tokens directly from their wallets without intermediaries. DEXs offer transparent and secure trading experiences.',
    image: require('../assets/images/Layer 13_044241.png'),
  },  {
    title: 'Staking',
    description: 'Users can stake HEXA tokens to earn rewards and participate in network security and governance. Staking is crucial for maintaining the integrity of the blockchain and offers participants a way to earn passive income.',
    image: require('../assets/images/Layer 14_044239.png')
  }
  
];

const defiIntegration = {
  title: 'DeFi Integration and Benefits',
  description: 'HexaLink offers a comprehensive suite of DeFi services designed to enhance financial freedom and efficiency. Our integration with various DeFi protocols and services ensures that users have access to a wide range of financial tools and opportunities.',
  image: defiimg,
};

const DeFiPage = () => {
  return (
    <Container className="my-5">
        <br />
        <br />
      <h1 className="text-center mb-4">DeFi on HexaLink</h1>

      <Row className="mb-5">
        <Col md={12}>
          <Card className="card-dark">
            <Card.Body>
              <div className='imageb'>
              <img src={defiIntegration.image} alt="" />
              </div>
              <br />
              <br />
              <br />
              <Card.Title>{defiIntegration.title}</Card.Title>
              <Card.Text>{defiIntegration.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2 className="text-center" style={{textAlign:'center', width:'100%'}}>DeFi Ecosystem</h2>
      <Row>
        {defiServices.map((service, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card className="card-dark">
               <Card.Body>
                <img className='egg' src={service.image} alt="" />
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <GetStarted/>
    </Container>
  );
};

export default DeFiPage;
