import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import GetStarted from './GetStarted';
import about from '../assets/images/cartoon-people-with-3d-glasses-blue-background_1108514-95124.jpg'

const assetTypes = [
  {
    title: 'In-Game Tokens',
    description: 'These are virtual currencies used within games to facilitate transactions. Players can earn, spend, and trade these tokens within the game ecosystem. They are essential for purchasing items, unlocking features, and participating in various in-game activities.',
    image: require('../assets/images/pdefi.png'),
  },
  {
    title: 'NFTs (Non-Fungible Tokens)',
    description: 'NFTs represent unique digital assets such as characters, skins, and items that players can buy, sell, or trade. Each NFT is unique and can be used to unlock special abilities or access exclusive content within games.',
    image: require('../assets/images/cartoon-ai-robot-scene.png'),
  },
  {
    title: 'Virtual Real Estate',
    description: 'Players can own and trade virtual land or properties within the game world. These assets can be developed, customized, and monetized, providing a source of income and enhancing the gameplay experience.',
    image: require('../assets/images/house.png'),
  },
  {
    title: 'In-Game Equipment and Gear',
    description: 'Includes weapons, armor, and other equipment that players can acquire and use within the game. These assets often have different attributes and can be upgraded or traded with other players.',
    image: require('../assets/images/cartoon-robot-with-colorful-eyes-legs-generative-ai_1028873-11138_prev_ui.png'),
  },
  {
    title: 'Collectibles',
    description: 'Special items or memorabilia that players can collect and showcase. These may include rare items, event-specific rewards, or other unique assets that add value to the player’s collection.',
    image: require('../assets/images/pngwing.com (36).png'),
  },
  {
    title: 'Customization Items',
    description: 'Items that allow players to personalize their characters or game environments. This includes skins, avatars, and other cosmetic items that enhance the visual appeal and uniqueness of the player’s assets.',
    image: require('../assets/images/pngwing.com (30).png')
  },
];

const assetManagement = {
  title: 'Asset Management and Integration',
  description: 'HexaLink provides robust infrastructure for managing gaming assets, ensuring secure and seamless integration into the gaming ecosystem.',
  details: [
    {
      title: 'Decentralized Asset Ownership',
      description: 'Gaming assets are stored on the blockchain, ensuring that ownership is decentralized and immutable. Players have full control over their assets, and transactions are transparent and verifiable.',
      image: require('../assets/images/c5.png'),
    },
    {
      title: 'Cross-Game Compatibility',
      description: 'Assets can be used across different games within the HexaLink ecosystem, allowing players to transfer and utilize their items in multiple gaming environments. This enhances the value and utility of assets.',
      image: require('../assets/images/c2.png'),
    },
    {
      title: 'Secure Transactions',
      description: 'All transactions involving gaming assets are secured using advanced cryptographic techniques. This ensures that asset transfers are safe, preventing fraud and unauthorized access.',
      image: require('../assets/images/c1.png'),
    },
    {
      title: 'Dynamic Asset Valuation',
      description: 'The value of gaming assets can fluctuate based on market demand and rarity. HexaLink provides tools for tracking and managing asset value, enabling players to make informed decisions.',
      image: require('../assets/images/icon3.png'),
    },
    {
      title: 'Integration with DeFi',
      description: 'Gaming assets can be integrated with decentralized finance (DeFi) protocols, allowing players to earn rewards, stake assets, or participate in liquidity pools using their gaming items.',
      image: require('../assets/images/icon2.png'),
    },
  ],
  image: require('../assets/images/icon2.png'),
};

const GamingAssetsPage = () => {
  return (
<div>
    <img src={about} style={{borderBottom:'2px solid white'}} alt="" />
    <Container className="box">
      <br />
      <br />
      <h1 className="text-center mb-4">NFTs & Gaming Assets</h1>

      <Row className="mb-5">
        <Col md={12}>
          <Card className="card-dark">
            <Card.Body>
              <Card.Title>{assetManagement.title}</Card.Title>
              <Card.Text>{assetManagement.description}</Card.Text>
              <ul className='home-grid'>
                {assetManagement.details.map((detail, index) => (
                  <li key={index}>
                    <img className="icons"src={detail.image} alt="" />
                    <strong>{detail.title}</strong> {detail.description}
                  </li>
                ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2 className="text-center mb-7">Types of Gaming Assets</h2>
      <Row>
        {assetTypes.map((asset, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card className="card-dark">
              <Card.Body>
              <img className='egg' src={asset.image} alt="" />
                <Card.Title>{asset.title}</Card.Title>
                <Card.Text>{asset.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <GetStarted/>
    </Container>
    </div>
  );
};

export default GamingAssetsPage;
