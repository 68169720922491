// src/components/Announcements/AnnouncementDetails.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../components/utils/Firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../hooks/Context';
import Loader from '../components/utils/Loader';

const AnnouncementDetails = () => {
  const { id } = useParams();
  const [announcement, setAnnouncement] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const announcementDoc = await getDoc(doc(db, 'announcements', id));
        if (announcementDoc.exists()) {
          setAnnouncement(announcementDoc.data());
          if (currentUser) {
            await setDoc(doc(db, `users/${currentUser.uid}/readAnnouncements`, id), { read: true });
          }
        } else {
          console.log('No such announcement!');
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching announcement: ", error);
        setLoading(false);
      }
    };

    fetchAnnouncement();
  }, [id, currentUser]);

  if (loading) {
    return <Loader/>;
  }

  if (!announcement) {
    return <p>Announcement not found.</p>;
  }

  return (
    <div className='announcement-details'>
      <h1>{announcement.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
      <br/>
  
    </div>
  );
};

export default AnnouncementDetails;
