import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/Context'; // Assuming useAuth provides currentUser and userData
import { Button } from 'react-bootstrap';
import VerificationModal from './VerificationModal';
import './Profile.css';
import { FaUserCircle, FaCopy } from 'react-icons/fa';
import LogoutButton from '../components/Auth/logoutButton';
import Loader from '../components/utils/Loader';

const Profile = () => {
  const { currentUser, userData } = useAuth(); // Extract currentUser and userData from context
  const [showModal, setShowModal] = useState(false);

  // If userData is not available, show loader
  if (!userData) {
    return <Loader />;
  }

  // Destructure necessary data from userData
  const { accountType, status, username, id, email, address, referralCount, referralLink } = userData;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  return (
    <div className="profile-container">
      <h2 className='user'><FaUserCircle /> {username}</h2>
      <p><strong>Email</strong> <span className='span'>{email}</span></p>
      <p>
        <strong>ID</strong> <span className='span'>
          {id} <FaCopy onClick={() => copyToClipboard(id)} style={{ cursor: 'pointer' }} />
        </span>
      </p>
      <p><strong>My Community</strong> <span className='span'>{referralCount}</span></p>
      {referralLink && (
        <p>
          <strong>Referral Link</strong> <span className='span'>
            {referralLink} <FaCopy onClick={() => copyToClipboard(referralLink)} style={{ cursor: 'pointer' }} />
          </span>
        </p>
      )}
      <VerificationModal show={showModal} handleClose={() => setShowModal(false)} />
      <LogoutButton />
    </div>
  );
};

export default Profile;
