import React, { useState } from 'react';
import { FaFileContract, FaCoins, FaGamepad, FaExchangeAlt, FaSeedling, FaUserShield, FaWallet, FaUserFriends, FaVoteYea, FaCheck, FaNetworkWired, FaGift, FaHandsHelping, FaCalendarAlt, FaCubes, FaTractor, FaDoorOpen, FaConfluence, FaRocket, FaFunnelDollar, FaMoneyBillWaveAlt, FaArrowRight, FaFantasyFlightGames, FaDonate } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import mine from "../assets/images/mine.png"
import './Web3.css';



const sections = [
  {
    title: "DeFi Features",
    items: [
      { icon: <FaExchangeAlt className="grid-item-icon" />, label: "Swap" },
      { icon: <FaCoins className="grid-item-icon" />, label: "Liquidity Pools" },
      { icon: <FaTractor className="grid-item-icon" />, label: "Yield Farming" },
      { icon: <FaDonate className="grid-item-icon" />, label: "Lending and Borrowing" },
      { icon: <FaRocket className="grid-item-icon" />, label: "Launchpad" },
      { icon: <FaMoneyBillWaveAlt className="grid-item-icon" />, label: "HexaPay" }
    ],
  },
  {
    title: "GameFi Features",
    items: [
      { icon: <FaGamepad className="grid-item-icon" />, label: "Play-to-Earn Hub" },
      { icon: <FaCubes className="grid-item-icon" />, label: "In-Game Economy" },
      { icon: <FaFantasyFlightGames className="grid-item-icon" />, label: "NFT Integration" },
      { icon: <FaSeedling className="grid-item-icon" />, label: "Staking and Farming" },
    ],
  },
 
  {
    title: "Governance",
    items: [
      { icon: <FaNetworkWired className="grid-item-icon" />, label: "DAO" },
      { icon: <FaVoteYea className="grid-item-icon" />, label: "Vote" },
      { icon: <FaConfluence className="grid-item-icon" />, label: "Proposal" },
    ],
  },
  {
    title: "Cross-Platform Compatibility",
    items: [
      { icon: <FaNetworkWired className="grid-item-icon" />, label: "Multi-Chain Bridge" },
      { icon: <FaGamepad className="grid-item-icon" />, label: "Cross-Game Assets" },
    ],
  },
];

const Web3AppFeatures = () => {
  const [showModal, setShowModal] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const handleIconClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setIsExiting(true);
    setTimeout(() => {
      setShowModal(false);
      setIsExiting(false);
    }, 500); // Match the duration of the exit animation
  };

  return (
    <div className="container2">
 
      {sections.map((section) => (
        <div key={section.title} className="section2">
          <h2 className="section-title">{section.title}<FaArrowRight/></h2>
          <div className="grid2">
            {section.items.map((item, index) => (
              <div key={index} className="grid2-item" onClick={handleIconClick}>
                <p className='grid2-item-icon'>{item.icon}</p>
                <span style={{color:'white'}}>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* Bootstrap Modal */}
      {showModal && (
        <div className={`modal show fade d-block ${isExiting ? 'modal-exit' : ''}`} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <img className='mine' src={mine}/>
            
                <button type="button" className="close" aria-label="Close" onClick={handleCloseModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <p>🚀 We're excited to announce that we are in the process of implementing the fully functional Hexalink ecosystem. In the meantime, enjoy farming $HEXA coin and help spread the word! 🌟</p> </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-dark" onClick={handleCloseModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Web3AppFeatures;
