import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/Context'; // Assuming useAuth provides currentUser and userData
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../components/utils/Firebase';
import { FaAndroid } from 'react-icons/fa';
import GridWidget from '../components/Widget';
import Slider from '../components/Carousel';
import Auth from '../components/Auth/Auth';
import PdfViewer from '../components/utils/Pdf';
import Loader from '../components/utils/Loader';
import ReferralContest from './Referralcontest';

const adminEmails = ['admin@example.com', 'hexalinkwebservice@gmail.com']; // Add your admin emails here

const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser, userData } = useAuth(); // Extract currentUser and userData from context
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAdminStatusAndUserCount = async () => {
      if (currentUser) {
        const userIsAdmin = adminEmails.includes(currentUser.email);
        setIsAdmin(userIsAdmin);

        if (userIsAdmin) {
          const usersCollection = collection(db, 'users');
          const userSnapshot = await getDocs(usersCollection);
          const totalUsersCount = userSnapshot.size;
          setTotalUsers(totalUsersCount);
        }
      }
    };

    const ensureUserData = async () => {
      if (currentUser && userData) {
        const userRef = doc(db, 'users', currentUser.uid);

        // Check if `balance` is undefined and set it to 0 if necessary
        if (userData.balance === undefined) {
          await setDoc(userRef, { balance: 10 }, { merge: true });
        }
      }
    };

    const init = async () => {
      setIsLoading(true);
      await fetchAdminStatusAndUserCount();
      await ensureUserData();
      setIsLoading(false);
    };

    init();
  }, [currentUser, userData]);

  if (isLoading || !userData) {
    return <Loader />;
  }

  const { balance = 0 } = userData; // Default to 0 if balance is missing

  const formatBalance = (balance) => {
    // Format balance with commas as thousand separators
    return balance.toLocaleString(undefined, {
      minimumFractionDigits: 2, // Ensure two decimal places
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="dash">
      <div>
        <div className="home-grid">
          <div className="grid" onClick={() => navigate("/farm")}>
            <p>
              <img className="logob" src={require('../assets/images/token.png')} alt="token" />{' '}
              {formatBalance(balance)}
            </p>
          </div>
          {isAdmin && (
            <div className="grid">
              <p>Total Users: {totalUsers}</p>
            </div>
          )}
        </div>
        <div className="hexa-container">
          <div className="overlay">
            <h1 className="title">Hexa Miniatures</h1>
            <p className="description1">Get ready to breed, train, and battle for championship and additional HEXA rewards.</p>
            <a href="https://opensea.io/collection/hexa-miniature" target="_blank" rel="noopener noreferrer">
              <button style={{ width: '100px', background: 'black' }}>Opensea</button>
            </a>
          </div>
        </div>
        <br />
        <GridWidget />
        <Slider />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Dashboard;
