import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../components/utils/Firebase';
import { collection, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../hooks/Context';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { stripHtmlAndTruncate } from '../components/utils/helpers';
import Loader from '../components/utils/Loader';
import { FaPen, FaTrash } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editingAnnouncement, setEditingAnnouncement] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Function to fetch announcements and user read status
  const fetchAnnouncements = useCallback(async () => {
    try {
      setLoading(true);

      const announcementCollection = collection(db, 'announcements');
      const announcementSnapshot = await getDocs(announcementCollection);
      const announcementList = announcementSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate() // Convert Firestore timestamp to JavaScript Date
      }));

      let userReadList = [];
      if (currentUser) {
        const userReadCollection = collection(db, `users/${currentUser.uid}/readAnnouncements`);
        const userReadSnapshot = await getDocs(userReadCollection);
        userReadList = userReadSnapshot.docs.map(doc => doc.id);
      }

      const sortedList = announcementList.map(announcement => ({
        ...announcement,
        read: userReadList.includes(announcement.id)
      })).sort((a, b) => b.timestamp - a.timestamp); // Sort by most recent

      setAnnouncements(sortedList);
    } catch (error) {
      console.error("Error fetching announcements: ", error);
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleShowModal = (announcement = null) => {
    setEditingAnnouncement(announcement);
    setTitle(announcement ? announcement.title : '');
    setContent(announcement ? announcement.content : '');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingAnnouncement(null);
  };

  const handleSaveAnnouncement = async () => {
    if (!title || !content) return;

    try {
      const timestamp = new Date();
      const announcementRef = editingAnnouncement
        ? doc(db, 'announcements', editingAnnouncement.id)
        : doc(collection(db, 'announcements'));

      await setDoc(announcementRef, { title, content, timestamp }, { merge: true });

      setShowModal(false);
      setEditingAnnouncement(null);
      setTitle('');
      setContent('');

      // Refetch announcements
      fetchAnnouncements();
    } catch (error) {
      console.error("Error saving announcement: ", error);
    }
  };

  const handleDeleteAnnouncement = async (id) => {
    try {
      await deleteDoc(doc(db, 'announcements', id));
      setAnnouncements(prevAnnouncements => prevAnnouncements.filter(announcement => announcement.id !== id));
    } catch (error) {
      console.error("Error deleting announcement: ", error);
    }
  };

  const handleAnnouncementClick = async (announcementId) => {
    if (!currentUser) return;

    try {
      await setDoc(doc(db, `users/${currentUser.uid}/readAnnouncements`, announcementId), { read: true });
      navigate(`/announcement/${announcementId}`);
    } catch (error) {
      console.error("Error setting announcement read status: ", error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='announcements-container'>
      <h5>Announcements📢</h5>
      {currentUser && currentUser.email === "hexalinkwebservice@gmail.com" && (
        <Button variant="primary" onClick={() => handleShowModal()}>Create Announcement</Button>
      )}
      <div>
        {announcements.map(announcement => (
          <div
            key={announcement.id}
            className="announcement-list"
            style={{ fontWeight: announcement.read ? 'normal' : 'bold' }}
          >
            <Link className='linked' to="#" onClick={() => handleAnnouncementClick(announcement.id)}>
              <h3>{announcement.title}</h3>
              <p>{stripHtmlAndTruncate(announcement.content, 50)}</p>
              <h6 className='last-time'>{formatDistanceToNow(announcement.timestamp, { addSuffix: true })}</h6>
            </Link>
            {currentUser && currentUser.email === "hexalinkwebservice@gmail.com" && (
              <div style={{ display: "flex" }}>
                <button style={{ flex: 1 }} onClick={() => handleShowModal(announcement)} className="ml-2"><FaPen /></button>
                <button style={{ flex: 1 }} onClick={() => handleDeleteAnnouncement(announcement.id)} className="ml-2"><FaTrash /></button>
              </div>
            )}
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editingAnnouncement ? 'Edit Announcement' : 'Create Announcement'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="announcementTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="announcementContent" className="mt-3">
              <Form.Label>Content</Form.Label>
              <ReactQuill value={content} onChange={setContent} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          <Button variant="primary" onClick={handleSaveAnnouncement}>Save</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Announcements;
