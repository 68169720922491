import React from 'react';
import img from "../assets/images/h4.jpg"
 // Import the CSS file for styling

function StakingComponent() {
  return (
    <div className="nft-container">
      <div className="nft-images">
        <img src={img} alt="" />
        {/* Insert your images here */}
        {/* You can use img tags or background images */}
      </div>
      <div className="nft-content">
        <h2>Coming Soon</h2>
        {/* Add any additional text or elements here */}
      </div>
    </div>
  );
}

export default StakingComponent;
