import React from 'react';

const PrivacyPolicy = () => (
  <div className='privacy'>
    <h1>Privacy Policy</h1>
    <p>
      PLEASE READ THE FOLLOWING PRIVACY POLICY CAREFULLY. WHEN YOU USE ANY GAME, APP, OR SERVICE FROM HEXALINK, ITS SUBSIDIARIES OR AFFILIATES (“HEXALINK”) YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS.
    </p>
    <p>
      HexaLink respects your privacy and understands the importance of protecting your personal information. We will only collect information we need to fulfill your requests and our legitimate business objectives. We will never send you marketing communications without your consent, and we will never share your personal information with third parties that are not bound by our privacy policy unless you tell us we can.
    </p>
    <p>
      By downloading or using any Game or App or by registering for an Account, we may transfer and store your account information. By registering, you agree that HexaLink may process your data in accordance with the following privacy policy and that you will abide by HexaLink’s Terms of Service.
    </p>
    <p>
      BY DOWNLOADING OR USING A GAME OR APP OR BY REGISTERING FOR AN ACCOUNT, YOU AGREE TO THE HEXALINK PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS POLICY, PLEASE DO NOT USE ANY HEXALINK GAME, APP OR SERVICE. If we change our Privacy Policy, we will post those changes to this Privacy Policy, the home page, or other places so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material or significant changes to this Privacy Policy, we will notify you by email and/or by a notice at www.hexalink.org and/or during login. Revisions to terms affecting existing HexaLink services shall be effective thirty (30) days after posting. Your continued use of our Games, Apps, and services will signify your acceptance of the changes to our Privacy Policy.
    </p>

    <h2>HEXALINK ONLINE PRIVACY POLICY:</h2>
    <h3>INTRODUCTION</h3>
    <p>
      HexaLink respects the privacy rights of consumers and recognizes the importance of protecting the information collected about you. We have adopted this global Privacy Policy to explain how we store and use personal and non-personal information we collect online on our websites, during your use of our website, online products, and/or services (including online gameplay).
    </p>
    <p>
      HEXALINK DOES NOT KNOWINGLY COLLECT INFORMATION FROM CHILDREN UNDER 13. IF YOU ARE UNDER 13, PLEASE DO NOT SUBMIT ANY PERSONALLY IDENTIFIABLE INFORMATION TO HEXALINK.
    </p>

    <h3>WHAT IS PERSONAL INFORMATION AND WHEN DOES HEXALINK COLLECT IT?</h3>
    <p>
      HexaLink collects both personal and non-personal consumer information. Personal information collected by HexaLink is discussed below in this section. Non-personal information is discussed below in Section 3.
    </p>

    <h4>A. Personal Information collected by HexaLink</h4>
    <p>
      Personal information is information that identifies you and that may be used to contact you online or offline. HexaLink may collect personal information during registration and/or use of our Games, Apps, and Services. HexaLink also may maintain log files which contain IP addresses. An IP address is a numeric address that may be assigned to your computer by your Internet Service Provider. In general, we use log files to monitor activity in our Games and Apps and to troubleshoot technical problems. In the event of user abuse of our Games, Apps, or Services, however, we may block certain IP addresses. If available, IP addresses and device IDs may be used to personally identify you to enforce our Terms of Service.
    </p>

    <h4>B. Personal information provided to HexaLink by Third Parties</h4>
    <p>
      We may also receive other personal information from third parties in connection with the operation and distribution of our Games, Apps, and Services as well as market and demographic studies that we use to supplement personal information provided directly by you. As with all other information, we use this data only for purposes consistent with this policy.
    </p>

    <h3>WHAT IS NON-PERSONAL INFORMATION AND WHEN DOES HEXALINK COLLECT IT?</h3>
    <p>
      Non-personal information, alone, cannot be used to identify or contact you. HexaLink collects non-personal information about your use of our Games, Apps, and Services.
    </p>

    <h4>A. What types of non-personal information does HexaLink collect?</h4>
    <p>
      When you use HexaLink Games, Apps, and Services, we may collect certain non-personal demographic information including gender, zip code, information about your computer, hardware, software, platform, media, or mobile device. We also collect other non-personal information such as feature usage, gameplay behaviors, scores, user rankings as well as other data that you may provide in surveys, via your account preferences and online profiles or through purchases, for instance. We may also receive either non-personal or public information from third parties in connection with market and demographic studies that we use to supplement personal information provided directly by you.
    </p>

    <h4>B. How does HexaLink collect non-personal information?</h4>
    <p>
      HexaLink collects non-personal information in addition to personal information when you use our Games, Apps, and Services. In addition, we might use cookies and other technologies to passively collect non-personal demographic information and personalize your experience on our sites.
    </p>

    <h4>Cookies</h4>
    <p>
      Cookies are small files applied to your Internet browser to track movements within websites. We may link cookie information to personal information. Cookies link to information regarding what items you have viewed. Also, we may use cookies to deliver content specific to your interest and to monitor website or game usage. Most browsers are automatically set to accept cookies whenever you visit a website. You can disable cookies or set your browser to alert you when cookies are being sent. Please note that this privacy policy covers the use of cookies by HexaLink only and does not cover the use of cookies by any advertisers.
    </p>

    <h4>Analytic metrics tools and other technologies</h4>
    <p>
      HexaLink may also use its own proprietary analytic metrics tool and other analytics technologies to collect non-personal information when you use our online products and services.
    </p>

    <h3>WHAT HAPPENS TO THE INFORMATION HEXALINK COLLECTS?</h3>

    <h4>A. How HexaLink uses your information</h4>
    <p>
      HexaLink uses your information to operate HexaLink’s Games, Apps, and Services, fulfill your specific requests, and to send you profile and account-related information. In addition, if you choose to receive communications from us about HexaLink products and news, the personal information you provide will allow us to send you messages about things including new features, enhancements, contests, and events of interest. You may also later opt out of this service from HexaLink.
    </p>

    <h4>B. Will HexaLink share my information with third parties?</h4>
    <p>
      HexaLink will never share your personal information with third parties without your consent. We may, however, share non-personal data in the aggregate (in a form that does not personally identify you) with third parties. You may also opt-in to allow HexaLink to share your personal information with companies and organizations that provide products or services that we believe may be of interest to you.
    </p>
    <p>
      From time to time, HexaLink may employ third-party contractors to collect personal information on our behalf to provide email delivery, product, or promotional fulfillment, contest administration, credit card processing, shipping, or other services on our sites. When requesting these services, you may be asked to supply your name, mailing address, telephone number, and email address to our contractors. We ask some third-party contractors, such as credit agencies or market research firms, to supplement personal information that you provide to us for our own marketing and demographic studies so that we can consistently improve our sites and better meet our visitors’ needs and preferences.
    </p>
    <p>
      When our third-party contractors collect and/or have access to the personal information you provide to HexaLink, HexaLink requires that they adhere to our stated privacy policies and protect the confidentiality of personal information they collect or have access to in the course of their engagement by HexaLink. These third parties are prohibited from using your personal information for any other purpose without your specific consent.
    </p>
    <p>
      We may also disclose personal information to law enforcement or the appropriate civil authorities to enforce legal rights and comply with the law, or to comply with an order from a government entity or other competent authority, or when we have reason to believe that a disclosure is necessary to address potential or actual injury or interference with our rights, property, operations, users, or others who may be harmed or may suffer loss or damage, or when we believe that disclosure is necessary to protect our rights, combat fraud, and/or comply with a judicial proceeding, court order, or legal process served on HexaLink.
    </p>

    <h3>WHERE IS THE INFORMATION HELD?</h3>
    <p>
      HexaLink utilizes data storage facilities located in the United States.
    </p>

    <h3>HOW DOES HEXALINK PROTECT YOUR PERSONAL INFORMATION?</h3>
    <p>
      The security of your personal information is important to us. We follow generally accepted industry standards, including the guidelines developed by the Online Privacy Alliance and the US Federal Trade Commission to protect the personal information submitted to us, both during transmission and in storage. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially reasonable means to protect your personal information, we cannot guarantee its absolute security. If you have any questions about security on our website, please contact us at privacy@hexalink.org.
    </p>

    <h3>REVIEW, CORRECTION OF YOUR INFORMATION, REQUESTING REMOVAL FROM MAILING LISTS AND DEACTIVATING AN ACCOUNT</h3>
    <p>
      You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes, and we will collect express consent from you if legally required prior to using your personal data for marketing purposes.
    </p>
    <p>
      You can correct, update, or delete your account information at any time, depending on the Game, App, or Service by logging on to our site, navigating to the Games and Apps account settings, or by contacting support@hexalink.org. We will be happy to review, update, or remove information as appropriate. We may still retain your information in our files however, to resolve disputes, enforce our user agreement, and due to technical and legal requirements and constraints related to the security, integrity, and operation of Games, Apps, and Services.
    </p>

    <h3>PUBLIC INFORMATION INCLUDING USER GENERATED CONTENT, ONLINE FORUMS, BLOGS, AND PROFILES</h3>
    <p>
      You may choose to disclose information about yourself in the course of contributing content to HexaLink sites or in public online chat rooms, blogs, message boards, user “profiles” for public view, or in similar forums on our sites. Information that you disclose in any of these forums is public information, and there is no expectation of privacy or confidentiality there. You should be aware that any personally identifiable information you submit in the course of these public activities can be read, collected, or used by other users of these forums, and could be used to send you unsolicited messages. We are not responsible for the personally identifiable information you choose to submit in these forums.
    </p>
    <p>
      If you post a video, image, or photo on one of our sites for public view you should be aware that these may be viewed, collected, copied, and/or used by other users without your consent. We are not responsible for the videos, images, or photos that you choose to submit to HexaLink’s site.
    </p>

    <h3>YOUR CONSENT IS IMPORTANT TO US</h3>
    <p>
      It is important to us that where we collect, use, or disclose your personal information we have your consent to do so. Depending on the sensitivity of the personal information, your consent may be implied, deemed (using an opt-out mechanism), or express. Express consent can be given orally, electronically, or in writing. Implied consent is consent that can reasonably be inferred from your action or inaction.
    </p>
    <p>
      You may change or withdraw your consent at any time, subject to legal or contractual restrictions and reasonable notice, by contacting us at privacy@hexalink.org. In some circumstances, a change in or withdrawal of consent may severely limit our ability to provide services to, or acquire products or services from, you. All communications with respect to such withdrawal or variation of consent should be in writing and addressed to your consultant or our Officer.
    </p>

    <h3>CONTACT INFORMATION</h3>
    <p>
      If you have questions or concerns regarding this statement, you should contact the HexaLink Privacy Policy Administrator at privacy@hexalink.org.
    </p>
    <p>
      Thank you for reviewing our privacy policy!
    </p>
  </div>
);

export default PrivacyPolicy;
