import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/Context';
import { collection, addDoc, deleteDoc, doc, getDocs, updateDoc, increment, arrayUnion, setDoc } from 'firebase/firestore';
import { db } from '../components/utils/Firebase';
import telegramLogo from '../assets/images/telegram.png';
import twitterLogo from '../assets/images/twitter.jpg';
import youtubeLogo from '../assets/images/youtube.png';
import instagramLogo from '../assets/images/instagram.png';
import './Taskcenter.css';
import { FaAddressCard, FaArrowRight, FaPen } from 'react-icons/fa';

const adminEmail = 'hexalinkwebservice@gmail.com';

const TaskCenter = () => {
  const { currentUser } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [completedTaskIds, setCompletedTaskIds] = useState(new Set());
  const [taskType, setTaskType] = useState('');
  const [isTaskFormVisible, setTaskFormVisible] = useState(false);

  useEffect(() => {
    const fetchTasks = async () => {
      if (tasks.length === 0) {
        try {
          const tasksCollection = collection(db, 'tasks');
          const taskSnapshot = await getDocs(tasksCollection);
          const taskList = taskSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setTasks(taskList);
        } catch (error) {
          console.error("Error fetching tasks: ", error);
        }
      }
    };

    const fetchCompletedTasks = async () => {
      if (currentUser) {
        try {
          const completedTasksCollection = collection(db, 'users', currentUser.uid, 'completedTasks');
          const completedTasksSnapshot = await getDocs(completedTasksCollection);
          const completedTaskIdsList = completedTasksSnapshot.docs.map(doc => doc.id);
          setCompletedTaskIds(new Set(completedTaskIdsList));
        } catch (error) {
          console.error("Error fetching completed tasks: ", error);
        }
      }
    };

    fetchTasks();
    fetchCompletedTasks();
  }, [currentUser, tasks.length]);

  const isAdmin = currentUser && currentUser.email === adminEmail;

  const addTask = async (title, bonus, link, type) => {
    if (isAdmin) {
      const newTask = { title, bonus, link, type };
      try {
        const docRef = await addDoc(collection(db, 'tasks'), newTask);
        setTasks(prevTasks => [...prevTasks, { id: docRef.id, ...newTask }]);
        setTaskFormVisible(false);
      } catch (error) {
        console.error("Error adding task: ", error);
      }
    }
  };

  const deleteTask = async (taskId) => {
    if (isAdmin) {
      try {
        await deleteDoc(doc(db, 'tasks', taskId));
        setTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
      } catch (error) {
        console.error("Error deleting task: ", error);
      }
    }
  };

  const handleTaskAction = async (task, action) => {
    if (completedTaskIds.has(task.id)) return; // Prevent repeated task completion

    if (action === 'go') {
      window.open(task.link, '_blank');
      task.status = 'verify';
    } else if (action === 'verify') {
      window.open(task.link, '_blank');
      task.status = 'claim';
    } else if (action === 'claim') {
      try {
        const userDoc = doc(db, 'users', currentUser.uid);
        await updateDoc(userDoc, {
          balance: increment(task.bonus),
          completedTasks: arrayUnion(task.id)
        });

        // Add the completed task to the user's subcollection with the task.id as the document id
        const completedTaskDoc = doc(db, 'users', currentUser.uid, 'completedTasks', task.id);
        await setDoc(completedTaskDoc, {
          ...task,
          claim: true // Adding the 'claim' field as true
        });

        setCompletedTaskIds(prev => new Set(prev).add(task.id));
      } catch (error) {
        console.error("Error claiming task: ", error);
      }
    }
    setTasks(prevTasks => prevTasks.map(t => (t.id === task.id ? { ...t, status: task.status } : t)));
  };

  const getTaskIcon = (type) => {
    switch (type) {
      case 'telegram': return telegramLogo;
      case 'twitter': return twitterLogo;
      case 'youtube': return youtubeLogo;
      case 'instagram': return instagramLogo;
      default: return null;
    }
  };

  return (
    <div className="task-center">
      <h3>Task Center </h3>

      {/* Display Tasks */}
      <div className="tasks-list">
  {tasks.length > 0 ? (
    tasks
      .sort((a, b) => (completedTaskIds.has(a.id) ? 1 : -1))  // Sort tasks: completed at the bottom
      .map(task => (
        <div key={task.id} className="task">
          <img src={getTaskIcon(task.type)} alt={`${task.type} logo`} className="task-icon" />
          <h4>{task.title} <br /> <span>{task.bonus} HEXA</span></h4>
          {isAdmin && <button onClick={() => deleteTask(task.id)}>Delete Task</button>}
          
          {/* Display button */}
          <button
            disabled={completedTaskIds.has(task.id)} // Disable if task is completed
            onClick={() => handleTaskAction(task, task.status || 'go')}
          >
            {completedTaskIds.has(task.id) ? 'Done' : 
              task.status === 'verify' ? 'Verify' : 
              task.status === 'claim' ? 'Claim' : 
              <>GO <FaArrowRight /></>
            }
          </button>
        </div>
      ))
  ) : (
    <p>We got no task for you now, check again later.</p>
  )}
</div>


      {/* Floating Pen Icon for Admins */}
      {isAdmin && (
        <button
          className="floating-pen"
          onClick={() => setTaskFormVisible(true)}
          style={{
            position: 'fixed',
            bottom: '150px',
            right: '20px',
            backgroundColor: '#333',
            color: '#fff',
            border: 'none',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            fontSize: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          <FaPen />
        </button>
      )}

      {/* Task Creation Form Modal */}
      {isTaskFormVisible && (
        <div className="admin-controls-modal">
          <div className="admin-controls">
            <h3>Add New Task</h3>
            <input type="text" placeholder="Title" id="title" />
            <input type="number" placeholder="Bonus" id="bonus" />
            <input type="text" placeholder="Link" id="link" />
            <select onChange={(e) => setTaskType(e.target.value)} value={taskType}>
              <option value="">Select Task Type</option>
              <option value="telegram">Telegram</option>
              <option value="twitter">Twitter</option>
              <option value="youtube">YouTube</option>
              <option value="instagram">Instagram</option>
            </select>
            <button onClick={() => {
              const title = document.getElementById("title").value;
              const bonus = parseFloat(document.getElementById("bonus").value);
              const link = document.getElementById("link").value;
              if (title && bonus && link && taskType) {
                addTask(title, bonus, link, taskType);
              } else {
                alert('Please fill in all fields');
              }
            }}>Add Task</button>
            <button onClick={() => setTaskFormVisible(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskCenter;
