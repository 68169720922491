import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Loader from '../components/utils/Loader';
import './Airdrop.css'; // Import the CSS file for styling
import { useAuth } from '../hooks/Context';

const AirdropPage = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { userData, currentUser } = useAuth();

  // Use optional chaining and fallback for `fundingBalance`
  const fundingBalance = userData?.fundingBalance || 0;

  const connectWallet = async () => {
    if (!window.ethereum) {
      alert('Please install MetaMask!');
      return;
    }

    try {
      setIsLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send('eth_requestAccounts', []);
      const address = accounts[0];
      setWalletAddress(address);
    } catch (error) {
      console.error('Error connecting wallet:', error);
      alert('Failed to connect wallet.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 3)}...${address.slice(-3)}`;
  };

  return (
    <div className="airdrop-page">
      {/* {!walletAddress && !isLoading && (
        <button className="connect-wallet-btn" onClick={connectWallet}>
          Connect Wallet
        </button>
      )}

      {walletAddress && (
        <div className="wallet-info">
          <p>
            <strong>Wallet Address:</strong> {formatWalletAddress(walletAddress)}
          </p>
        </div>
      )} */}

<div className='claimable'>
      <h1 className="title">Airdrop Phase 1 Completed</h1>
      <p>
        <strong>Claimable Balance</strong>{' '}
        <img className="logob" src={require('../assets/images/token.png')} alt="token" />{' '}
        {fundingBalance / 1000}
      </p>

      </div>
      <p className="subtitle">Phase 2 In Progress</p>

      {isLoading && <Loader />}

      <div className="phase-info"> 
        <p>
          <strong>Listing Date</strong> TBA
        </p>
        <div className="cex-partners">
          <h3>CEX Partners</h3>
          <div className="cex-partners-grid">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="cex-partner">
                <p>TBA</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirdropPage;
