import React from 'react';
import './Timeline.css'; // Add your custom styling here

const timelineData = [
  {
    title: 'Airdrop Phase 1',
    description: 'Completed',
    status: 'completed', // status could be "completed", "ongoing", or "upcoming"
    date: '2024-11-25',
  },
  {
    title: 'Airdrop Phase 2',
    description: 'Ongoing',
    status: 'ongoing',
    date: '.......',
  },
  {
    title: 'Airdrop Phase 3',
    description: 'Premarket',
    status: 'upcoming',
    date: '........',
  },
  {
    title: 'CEX Onboarding',
    description: 'Onboarding exchanges',
    status: 'upcoming',
    date: '.........',
  },
  {
    title: 'Airdrop Claim',
    description: 'Token Distribution',
    status: 'upcoming',
    date: '.........',
  },
  {
    title: 'CEX Listing',
    description: 'Listing on exchanges',
    status: 'upcoming',
    date: '...........',
  },
];

const Timeline = () => {
  return (
    <div className="timeline-container">
      <h2>Launch Timeline</h2>
      <div className="timeline">
        {timelineData.map((phase, index) => (
          <div className="timeline-item" key={index}>
            <div
              className={`timeline-marker ${phase.status}`}
              style={{ backgroundColor: getStatusColor(phase.status) }}
            ></div>
            <div className="timeline-content">
              <h3>{phase.title}</h3>
              <p>{phase.description}</p>
              <small>{phase.date}</small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Helper function to determine the background color based on phase status
const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return '#4caf50'; // green for completed
    case 'ongoing':
      return '#ff9800'; // orange for ongoing
    case 'upcoming':
      return '#2196f3'; // blue for upcoming
    default:
      return '#2196f3'; // default blue
  }
};

export default Timeline;
