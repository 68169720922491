// src/components/BottomNav.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaChartLine, FaExchangeAlt, FaUser, FaWallet, FaGifts, FaTasks, FaTrophy, FaSpeakerDeck, FaBullhorn, FaUserFriends, FaGlobe, FaLock, FaCube } from 'react-icons/fa';


const BottomNavWeb = () => {
  return (
    <div className="bottom-nav">  
    
    <NavLink to="/dashboard" exact  activeClassName="active">
        <FaGlobe/>
        <span>Explore</span>
      </NavLink>
      <NavLink to="/Staking" activeClassName="active">
        <FaLock />
        <span>Stake</span>
      </NavLink>
      <NavLink to="/pool" activeClassName="active">
        <FaCube />
        <span>Liquidity Pool</span>
      </NavLink>
      <NavLink to="/swap" activeClassName="active">
        <FaExchangeAlt />
        <span>Swap</span>
      </NavLink>
      <NavLink to="/wallet" activeClassName="active">
        <FaExchangeAlt />
        <span>Wallet</span>
      </NavLink>
     
    </div>
  );
};

export default BottomNavWeb;
