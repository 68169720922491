import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/Context'; // Use the correct path to your context
import { db } from '../components/utils/Firebase';
import { doc } from 'firebase/firestore';
import { FaArrowRight, FaCopy, FaShareAlt, FaUser, FaUsers } from 'react-icons/fa';
import Loader from '../components/utils/Loader';
import { Button } from 'react-bootstrap';
import "./Referralbonus.css"
import hexaLogo from '../assets/images/token.png'

const ReferralPage = () => {
  const { currentUser, userData, loading } = useAuth(); // Access currentUser and userData from context
  const [claimedBonuses, setClaimedBonuses] = useState([]); // Optional: Could be used for any further features

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  
  };

  const shareReferralLink = () => {
    const referralLink = `https://hexalink.org/#/referral/${userData.referralCode}`;
    const shareMessage = `HexaLink Farming Season 2 is HERE! 🌟💰\n\nFarm HEXA, the token powering the HexaLink blockchain—enjoy blazing-fast 50,000 TPS and ultra-low fees of just $0.0001! 🔥 Be part of the central network connecting all blockchains and get rewarded for being an early adopter of this revolutionary ecosystem. 🌐⚡\n\nDon’t wait—start farming HEXA today: ${referralLink} ⏳`;

    if (navigator.share) {
      navigator.share({
        title: 'HexaLink Airdrop: Secure Your Rewards!',
        text: shareMessage,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      copyToClipboard(shareMessage);
     
     
    }
  };

  if (loading || !userData) {
    return <Loader />;
  }

  const { referralCode, referralCount } = userData;
  const referralBonusTotal = referralCount * 10; // Calculate the total referral bonus

  return (
    <div className="referral-container">
      <img className='title-img' src={require('../assets/images/interior.png')}/>
      <p className="intro-text">
      <h2 className="title">Invite Friends</h2>
      <p>
  By inviting friends and spreading the word, you can earn exciting rewards in the form of HEXA tokens. Here's how it works:
</p>
<p>
  For each friend you refer, you’ll earn <b>10 HEXA </b>, along with a boost in your mining rate per hour.
</p>
</p>

      <div className="referral-buttons">
        <button onClick={() => copyToClipboard(referralCode)} className="copy-button">
          Copy Code {referralCode} <FaCopy />
        </button>
        <button onClick={shareReferralLink} className="invite-button">
          Invite <FaShareAlt />
        </button>
      </div>

      <div className="referral-stats">
        <h3> Stats</h3>
        <div className='ref-bonus'>
        <div className="stats-item">
        <h3>My Friends</h3>
          <strong>{referralCount} <FaUsers/> </strong>
        </div>
        <div className="bonus-amount">
        <h3>Earned Bonus </h3>
          <strong>{referralBonusTotal}  <img src={hexaLogo} alt="Hexa Logo" className="logot" /> </strong>
        </div>
      </div>

</div>
     
    </div>
  );
};

export default ReferralPage;
