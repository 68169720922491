import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import GetStarted from './GetStarted';
import hexa from '../assets/images/computer-monitor-with-keyboard-mouse-it_995828-9657_prev_ui.png'

const features = [
  {
    title: 'Custom Blockchain Architecture',
    description: 'HexaLink utilizes a custom blockchain architecture developed using the Substrate framework. This design ensures flexibility, scalability, and high performance tailored to DeFi and GameFi applications.',
    image: require('../assets/images/computer-monitor-with-keyboard-mouse-it_995828-9657_prev_ui.png')
  },
  {
    title: 'Proof-of-Stake Consensus',
    description: 'HexaLink employs a Proof-of-Stake (PoS) consensus mechanism to ensure energy efficiency and security. Validators are selected based on the number of tokens they stake, promoting a decentralized network.',
    image: require('../assets/images/node.png')
  },
  {
    title: 'Cross-Chain Interoperability',
    description: 'Supports seamless interactions and asset transfers between different blockchain networks. This feature enhances liquidity and broadens the ecosystem for decentralized applications.',
    image: require('../assets/images/—Pngtree—blockchain_8875358.png'),
  },
  {
    title: 'EVM Compatibility',
    description: 'Compatible with Ethereum Virtual Machine (EVM), allowing deployment and execution of Ethereum-based smart contracts. Facilitates the migration of existing dApps and assets from Ethereum to HexaLink.',
   image: require('../assets/images/evm.png'),
  },
  {
    title: 'Layer 2 Solutions',
    description: 'Integrates Layer 2 solutions such as state channels and rollups to address scalability and transaction speed. Reduces transaction costs and increases throughput without compromising security.',
    image: require('../assets/images/block.png')
  },
  {
    title: 'Decentralized Governance',
    description: 'Incorporates a decentralized governance model, enabling HEXA token holders to participate in decision-making. Ensures the community has a voice in protocol upgrades.',
    image: require('../assets/images/world.png'),
  },
];

const blockchainDetails = {
  title: 'HexaLink Blockchain Performance Metrics',
  description: 'HexaLink is designed to deliver high performance, security, and efficiency to power the next era of decentralized gaming and defi innovation using batch transactions to increase scalabilty for global gaming adoption. HexaLink blockchain consist of side layers that operate independently to boost network throughput preventing network congestion at any time',
  metrics: [
    {
      title: 'Block Time',
      description: 'HexaLink achieves a block time of 1 second. This rapid block generation ensures that transactions are processed quickly, enhancing the user experience and network responsiveness.',
    image: require('../assets/images/pngwing.com (2).png')
    },
    {
      title: 'Transaction Speed',
      description: 'With an average transaction speed of 2,000,000 transactions per second (TPS), HexaLink supports high throughput for both DeFi and GameFi applications. This speed accommodates a large number of concurrent transactions without performance degradation. ',
      image: require('../assets/images/pngwing.com (6).png')
    },
    {
      title: 'Finality Time',
      description: 'HexaLink features an average finality time of 5 seconds. This means transactions are considered final and irreversible within 5 seconds, providing rapid confirmation and security against double-spending.',
      image: require('../assets/images/pngwing.com (14).png')
    },
    {
      title: 'Security',
      description: 'The blockchain employs advanced cryptographic techniques and a Proof-of-Stake consensus mechanism to ensure robust security. Regular security audits and updates are performed to safeguard against potential vulnerabilities.',
      image: require('../assets/images/pngwing.com (4).png')
    },
    {
      title: 'Node Setup',
      description: 'Setting up a HexaLink node involves configuring a validator node with a minimum stake of 10,000 HEXA tokens. Nodes are distributed globally to ensure high availability and decentralization of the network.',
      image: require('../assets/images/pngwing.com (5).png')
    },
    {
      title: 'Transaction Confirmation',
      description: 'HexaLink confirms transactions with an average of 6 confirmations. This ensures that each transaction is securely recorded and validated across the network, minimizing the risk of fraud or errors.',
      image: require('../assets/images/pngwing.com (13).png')
    },
  ],
  image: '/images/performance-metrics.png',
};

const HexaLinkPage = () => {
  return (
    <Container className="my-5">
      <br />
      <br />
      <h1 className="text-center mb-4">HexaLink Blockchain </h1>

      <Row className="mb-5">
        <Col md={12}>
          <Card className="card-dark">
            <Card.Body>
            <img className="imageb" src={hexa} alt="" />
            <br />
            <br />
              <Card.Title as='h2'>{blockchainDetails.title}</Card.Title>
              <Card.Text>{blockchainDetails.description}</Card.Text>
           
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ul className='home-grid'>
                {blockchainDetails.metrics.map((metric, index) => (
                  <li key={index}>
                    <img src={metric.image} alt="" />
                    <strong>{metric.title}</strong> {metric.description}
                  </li>
                ))}
              </ul>
<br />
      <h2 className="text-center mb-4" style={{width:'100%', textAlign:'center'}}>Key Features</h2>
      <Row>
        {features.map((feature, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card className="card-dark">
              <Card.Img variant="top" className='bg-dark' src={feature.image} />
              <Card.Body>
                <Card.Title>{feature.title}</Card.Title>
                <Card.Text>{feature.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
<br />
      <div>
      <h2 style={{width:"100%", textAlign:"center"}}>Implications for Transactions and Gaming Experience</h2>
          <ul className='home-grid2'>
          <li><strong>Instant In-Game Transactions</strong>Transactions are executed almost instantly, enhancing the overall gaming experience by reducing wait times and enabling real-time gameplay.</li>
          <li><strong>Scalable Gaming Ecosystem</strong>Supports a large number of simultaneous players and transactions, ensuring that the game environment remains stable and responsive even during peak usage.</li>
          <li><strong>Cost Efficiency</strong> With minimal gas fees and efficient transaction processing, HexaLink makes in-game transactions affordable, encouraging more frequent and diverse economic interactions within games.</li>
          <li><strong>Enhanced Game Mechanics</strong> Developers can create complex game mechanics that leverage blockchain features without compromising performance, allowing for innovative and engaging gameplay experiences.</li>
        </ul>
      </div>
      <GetStarted/>
    </Container>
  );
};

export default HexaLinkPage;
