import React, { useState, useEffect } from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { auth } from './components/utils/Firebase';
import { AuthProvider } from "./hooks/Context";
import "./App.css";

// Components
import SideBar from "./components/Sidebar/SideBar";
import BottomNav from "./components/BottomNavbar/BottomNavbar";
import Navbar from "./components/Navbar/Navbar";
import Loader from './components/utils/Loader';
import ScrollToTop from './components/utils/ScrollToTop';
import CryptoWidget from './components/utils/Ticker';
import Navigation from './pages/Navbar';
import Footer from './pages/Footer';

// Pages
import Dashboard from "./pages/Dashboard";
import SignUp from "./components/Auth/SignUp";
import SignIn from "./components/Auth/SignIn";
import ResetPassword from "./components/Auth/ResetPassword";
import Wallet from "./wallet/Wallet";
import Profile from "./profile/Profile";
import Staking from "./pages/Staking";
import Market from "./pages/Market";
import AdminTaskCenter from "./admin/TaskCenter";
import PointsTransfer from "./wallet/PointTransfer";
import ReceivePoints from "./wallet/PointReceive";
import ReferralPage from "./pages/Referral";
import AnnouncementDetails from "./announcement/DetailAnnouncement";
import Announcements from "./announcement/Announcement";
import Home from './pages/Home';
import NFTs from './pages/NFTs';
import Lending from './pages/Lending';
import DailyBonusContainer from './pages/DailyBonus';
import About from './pages/About';
import PrivacyPolicy from './pages/Privacy';
import ReferralSignUp from './components/Auth/Referral';
import NotificationCenter from './notification/Notification';
import Web3AppFeatures from './web3/Dasboard';
import BottomNavWeb from './web3/BottomNavbar/BottomNavbar';
import FarmComponent from './components/farm/Farm';
import Header from './components/Navbar/Header';
import MyNFT from './pages/MyNFT';
import Roadmap from './pages/Roadmap';
import Navbarmenu from './components/menu/Navbarmenu';
import HexaCoinPage from './pages/Hexa';
import HexaLinkPage from './pages/HexaLink';
import DeFiPage from './pages/Defi';
import TechnologyPage from './pages/Technology';
import GamingAssetsPage from './pages/GamingAsset';
import SmartContractsPage from './pages/SmartContract';
import AirdropPage from './pages/Airdrop';
import NFTMarketplace from './pages/NFTMarketplace';
import HNFTMarketplace from './pages/HEXAMarket';
import BoostSection from './pages/Boost';
import Timeline from './pages/Timeline';
import ReferralContest from './pages/Referralcontest';

function AuthenticatedApp() {
  return (
    <div>
      <SideBar />
      <div className="main">
        <Routes>
          <Route path="/" element={<FarmComponent />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/trade" element={<AdminTaskCenter />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/announcements/:id" element={<AnnouncementDetails />} />
          <Route path="/NFT" element={<NFTs />} />
          <Route path="/farm" element={<FarmComponent/>} />
          <Route path="/Swap" element={<NFTs />} />
          <Route path="/airdrop" element={<AirdropPage />} />
          <Route path="/contest" element={<ReferralContest />} />

          {/*
         
          <Route path="/boost" element={<BoostSection />} />
          <Route path="/notification" element={<NotificationCenter />} />
          <Route path="/marketplace/buy-with-pol" element={<NFTMarketplace />} />
          <Route path="/marketplace/buy-with-hexa" element={<HNFTMarketplace />} />
          */}
          <Route path="/about" element={<About />} />
          <Route path="/tasks" element={<Market />} />
          <Route path="/daily-bonus" element={<DailyBonusContainer />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/referral" element={<ReferralPage />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </div>
      <BottomNav />
    </div>
  );
}

function UnauthenticatedApp() {
  return (
    <div className='body'>    
    <div className='unAuth' >
      <Navbarmenu/>
      <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/HEXA" element={<HexaCoinPage/>} />
        <Route path="/hexachain" element={<HexaLinkPage/>} />
        <Route path="/roadmap" element={<Roadmap/>} />
        <Route path="/defi" element={<DeFiPage/>} />
        <Route path="/technology" element={<TechnologyPage/>} />
        <Route path="/nft&gaming" element={<GamingAssetsPage/>} />
        <Route path="/smart-contracts" element={<SmartContractsPage/>} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/referral/:referralCode" element={<ReferralSignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<SignIn />} />
      </Routes>
    </div>
    <Footer />
    </div>
  );
}


function Web3App() {
  return (
    <div className="body2">
      <Routes>
        <Route path='/' element={<Web3AppFeatures />} />
        <Route path="*" element={<Web3AppFeatures />} />
       
      </Routes>
      <BottomNavWeb />
    </div>
  );
}

function MainApp({ currentUser }) {
  const [activeComponent, setActiveComponent] = useState('Airdrop');
  const [loading, setLoading] = useState(false);

  // Retrieve the last selected component from localStorage on mount
  useEffect(() => {
    const storedComponent = localStorage.getItem('activeComponent');
    if (storedComponent) {
      setActiveComponent(storedComponent);
    }
  }, []);

  // Store the current active component in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('activeComponent', activeComponent);
  }, [activeComponent]);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000);
      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [loading]);

  const handleNavigation = (component) => {
    setLoading(true);
    setActiveComponent(component);
  };

  const renderComponent = () => {
    if (loading) {
      return <Loader />;
    }
    switch (activeComponent) {
      case 'Airdrop':
        return <AuthenticatedApp />;
      case 'Web3':
        return <Web3App />;
      default:
        return <AuthenticatedApp />;
    }
  };

  return (
    <div>
      <div className='header'>
  
        <Navbar />
        <div className='toggle'>
          <button onClick={() => handleNavigation('Airdrop')} disabled={activeComponent === 'Airdrop'}>Airdrops</button>
         <a href='https://dapp.hexalink.org' target='_blank'> <button style={{ marginLeft: '10px' }} disabled={activeComponent === 'Web3'}>Web 3</button></a>
      
        </div>
        <br/>
      </div>
      {renderComponent()}
    </div>
  );
}

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
      if (user) {
        setEmailVerified(user.emailVerified);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleResendVerification = () => {
    if (currentUser) {
      sendEmailVerification(currentUser)
        .then(() => {
          alert('Verification email sent.');
        })
        .catch((error) => {
          console.error('Error sending verification email:', error);
        });
    }
  };

  return (
    <AuthProvider>
      <HashRouter>
        <ScrollToTop />
        <div className="body2">
          {loading ? (
            <Loader />
          ) : currentUser ? (
              <MainApp currentUser={currentUser} />
          ) : (
            <UnauthenticatedApp />
          )}


        </div>
      
      </HashRouter>
    </AuthProvider>
  );
}

export default App;
