import React,{useState} from 'react';
import {NavLink, Link, useNavigate} from 'react-router-dom';
import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
import logo from '../../assets/images/loader.png';
import { FaBars, FaDoorClosed, FaDoorOpen, FaTimesCircle } from 'react-icons/fa';


const Navbarmenu = () => {
const navigate = useNavigate();
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
  };

    let boxClass = ["main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push('');
    }

    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
      
    const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    
    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active');
    }else {
        boxClassSubMenu.push('');
    }

   

    return (
    <header className="header__middle">
        <div className="containerr">
            <div className="row">

                {/* Add Logo  */}
                <div className="header__middle__logo">
                    <NavLink exact activeClassName='is-active' to="/">
                        <img className='logo' src={logo} alt="logo" /> 
                    </NavLink>
                </div>

                <div className="header__middle__menus">
                    <nav className="main-nav " >

                    {/* Responsive Menu Button */}
                    {isResponsiveclose === true ? <> 
                        <span className="menubar__button" style={{ display: 'none', float:'right', marginLeft:'80%' }} onClick={toggleClass} > <FaTimesCircle/>   </span>
                    </> : <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FaBars />   </span>
                    </>}


                    <ul className={boxClass.join(' ')}>
                    <li  className="menu-item" >
                        <NavLink exact activeClassName='is-active' onClick={toggleClass} to={`/`}> Home </NavLink> 
                    </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/About`}> About </NavLink> </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/HEXA`}> HEXA</NavLink> </li>
                    <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <Link to="#"> Ecosystem <FiChevronDown /> </Link>
                        <ul className={boxClassSubMenu.join(' ')} > 
                            <li> <NavLink onClick={toggleClass} activeClassName='is-active'  to={`/defi`}> DeFi </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/technology`}> Technology </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/nft&gaming`}> NFTs & Gaming Assets </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/smart-contracts`}> Contracts </NavLink> </li>
                        </ul>
                    </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/hexachain`}> Hexachain </NavLink> </li>
                  
                  <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/roadmap`}> Roadmap </NavLink> </li>
                   

                    </ul>


                    </nav>  
                    </div> 
                    <div  className="header__middle__login">< button style={{
    border:"3px solid purple", padding:"5px", width:"100px", margin:'17px 17px', textAlign:'center', borderBottomRightRadius:'20px', borderTopLeftRadius:'20px'
}} onClick={()=>navigate('/sign-in')}>Login </button ></div>  
        



        
        
            </div>
	    </div>
    </header>
    )
}

export default Navbarmenu
