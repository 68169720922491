import React from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const GetStarted = () => {
  return (
    <Container>
         {/* Call to Action */}
      <Row className="text-center">
        <Col md={12}>
          <Card className="card-dark">
            <Card.Body>
              <Card.Title>Get Involved</Card.Title>
              <Card.Text>
                Join the HexaLink community and start utilizing HEXA today. Participate in staking, DeFi services, and gaming. Stay updated with our latest news and developments.
              </Card.Text>
              <Link to="/sign-up">
                <Button variant="primary">Get Started</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  )
}

export default GetStarted