import React, { useContext, useEffect, useState } from 'react';
import { auth, db } from '../components/utils/Firebase'; // Make sure the Firebase imports are correct
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userId, setUserId] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const adminEmail = 'hexalinkwebservice@gmail.com'; // Replace with the actual admin email

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      setUserId(user.uid)

      if (user) {
        // Check if the user is an admin
        if (user.email === adminEmail) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }

        // Fetch user data from Firestore
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setUserData(userDoc.data()); // Store user data
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        // If no user is logged in, clear userData and admin status
        setUserData(null);
        setIsAdmin(false);
      }

      setLoading(false); // Set loading to false once the user data is fetched
    });

    return unsubscribe; // Clean up the listener on unmount
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isAdmin, userData, loading, userId }}>
      {children}
    </AuthContext.Provider>
  );
}
