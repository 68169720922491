// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhsPgSZfx3biXKq3AczXh0XNOQ1HjsLUc",
  authDomain: "hustle-online.firebaseapp.com",
  databaseURL: "https://hustle-online-default-rtdb.firebaseio.com",
  projectId: "hustle-online",
  storageBucket: "hustle-online.appspot.com",
  messagingSenderId: "102329868957",
  appId: "1:102329868957:web:6a86310f72e9b371f2aa74",
  measurementId: "G-C6FH7ZSK6M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize Firestore and other services as needed
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { db, auth,googleProvider };
const analytics = getAnalytics(app);