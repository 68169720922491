import React, { useState } from 'react';
import TaskCenter from '../components/task/TaskCenter';
import AdminTaskCenter from '../admin/TaskCenter';
import { useAuth } from '../hooks/Context';
import DailyBonus from '../components/task/DailyBonus';


const Market = () => {
  const { isAdmin } = useAuth(); // Get admin status from useAuth hook
  const [activeTab, setActiveTab] = useState('Daily-bonus'); // State for managing active tab

  return (
    <div className='market'>
  
         <DailyBonus />
      
    </div>
  );
};

export default Market;
