import React, { useState } from 'react';
import { useAuth } from '../hooks/Context';
import DailyBonus from '../components/task/DailyBonus';
import TaskCenter from './TaskCenter';


const Market = () => {
  const { isAdmin } = useAuth(); // Get admin status from useAuth hook
  const [activeTab, setActiveTab] = useState('Task-Center'); // State for managing active tab

  return (
    <div className='market'>
 <TaskCenter/>
    </div>
  );
};

export default Market;
