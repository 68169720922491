

import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { auth, db } from '../../components/utils/Firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, updateDoc, getDocs, collection, query, where, increment } from 'firebase/firestore';
import { useAuth } from '../../hooks/Context';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../utils/Loader';

function ReferralSignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const location = useLocation();


  useEffect(() => {
    const pathname = location.pathname;
    const referralCodeParam = pathname.split('/').pop();
    if (referralCodeParam) {
      setReferralCode(referralCodeParam);
 // Add console.log statement
    }
  }, [location.pathname]);
  
  


  const generateRandomUsername = () => {
    const randomChars = uuidv4().slice(0, 4);
    return `Hexauser${randomChars}`;
  };

  const generateRandomId = () => {
    return uuidv4().slice(0, 7);
  };



  const generateRandomString = (length) => {
    return Math.random().toString(36).substr(2, length).toUpperCase();
  };

  const saveUserData = async (user) => {
    const userId = generateRandomId();
    const randomString = generateRandomString(5);

    const userData = {
      email: user.email,
      username: generateRandomUsername(),
      balance: 10,
      fundingBalance:0,
      id: userId,
      referralCount: 0,
      referralCode: randomString,
    };

    try {
      await setDoc(doc(db, 'users', user.uid), userData);

      if (referralCode) {
        const referrerQuery = query(collection(db, 'users'), where('referralCode', '==', referralCode));
        const querySnapshot = await getDocs(referrerQuery);

        if (!querySnapshot.empty) {
          const referrerDoc = querySnapshot.docs[0];
          await updateDoc(referrerDoc.ref, {
            referralCount: increment(1),
            balance: increment(10)
          });
        }
      }
    } catch (error) {
      console.error('Error saving user data: ', error);
    }
  };

  const firebaseErrorMessages = {
    'auth/email-already-in-use': 'This email is already in use.',
    'auth/invalid-email': 'Invalid email address.',
    'auth/operation-not-allowed': 'Operation not allowed.',
    'auth/weak-password': 'Password is too weak.',
    // Add more error codes and messages as needed
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true); // Set loading state to true when sign-up starts

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await saveUserData(userCredential.user);
      navigate('/'); // Navigate to home page
    } catch (error) {
      const errorMessage = firebaseErrorMessages[error.code] || 'An error occurred. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false); // Set loading state to false after sign-up attempt
    }
  };

  if (currentUser) {
    navigate('/');
  }

  return (
    <div className="container">
      <h2>Sign Up</h2>
      {isLoading ? ( // Conditionally render the loader or the form
        <Loader /> // Show loader when loading state is true
      ) : (
        <form onSubmit={handleSignUp}>
          <div className="mb-3">
            <input 
              type="email" 
              className="form-control"
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Email" 
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="password" 
              className="form-control"
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="password" 
              className="form-control"
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)} 
              placeholder="Confirm Password" 
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="text" 
              className="form-control"
              value={referralCode} 
              onChange={(e) => setReferralCode(e.target.value)} 
              placeholder="Referral Code (optional)" 
              readOnly
            />
          </div>
          <button type="submit" style={{width:"100%", margin:"0 auto", display:"block"}}>Sign Up</button>
          <span> Already have an account? <i> <Link to={`/sign-in`}>Log in</Link></i></span>
        </form>
      )}
      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
}

export default ReferralSignUp;
