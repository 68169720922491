import React, { useState, useEffect } from 'react';
import { db } from '../../components/utils/Firebase';
import { doc, updateDoc, serverTimestamp, increment } from 'firebase/firestore';
import { useAuth } from '../../hooks/Context';
import { useNavigate } from 'react-router-dom';
import img from "../../assets/images/bonus.png";
import Loader from '../utils/Loader';
import './DailyBonus.css'; // Import the CSS file
import tick from '../../assets/images/star.png'

const DailyBonus = () => {
  const [canClaim, setCanClaim] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const [streak, setStreak] = useState(0);
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    const checkClaimStatus = () => {
      if (userData) {
        const lastClaimTime = userData.lastClaimTime?.toDate();
        const userStreak = userData.streak || 0;
        const now = new Date();

        if (!lastClaimTime || now - lastClaimTime >= 24 * 60 * 60 * 1000) {
          setCanClaim(true);
        } else {
          const nextClaimTime = new Date(lastClaimTime.getTime() + 24 * 60 * 60 * 1000);
          setTimeLeft(nextClaimTime - now);
          setCanClaim(false);
        }
        setStreak(userStreak);
        setLoading(false);
      }
    };

    checkClaimStatus();
  }, [currentUser, userData, navigate]);

  const calculateReward = (streak) => {
    if (streak === 1) return 0.2; // Reward for streak 1
    if (streak === 2) return 0.4; // Reward for streak 2
    if (streak === 3) return 0.6; // Reward for streak 3
    if (streak === 4) return 0.8; // Reward for streak 4
    if (streak === 5) return 1.0; // Reward for streak 5
    if (streak === 6) return 1.2; // Reward for streak 6
    if (streak === 7) return 1.4; // Reward for streak 7
    if (streak === 8) return 1.6; // Reward for streak 8
    if (streak === 9) return 1.8; // Reward for streak 9
    if (streak === 10) return 2.0; // Reward for streak 10
    if (streak >= 11) return 2.5; // Cap at 5 points after day 10
    if (streak >= 12) return 2.5; // Cap at 5 points after day 10
    return 2.5; // Default case
  };
  

  const handleClaimBonus = async () => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const lastClaimTime = userData.lastClaimTime?.toDate();
      const now = new Date();

      let newStreak = streak;

      if (lastClaimTime && now - lastClaimTime < 48 * 60 * 60 * 1000) {
        newStreak += 1;
      } else {
        newStreak = 1;
      }

      const reward = calculateReward(newStreak);

      await updateDoc(userRef, {
        balance: increment(reward),
        lastClaimTime: serverTimestamp(),
        streak: newStreak
      });

      // Update the local userData state
      setCanClaim(false);
      setTimeLeft(24 * 60 * 60 * 1000);
      setStreak(newStreak);
    } catch (error) {
      console.error("Error claiming bonus: ", error);
    }
  };

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1000);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  if (loading) {
    return <Loader />;
  }

  const formatTime = (milliseconds) => {
    const hours = Math.floor(milliseconds / (60 * 60 * 1000));
    const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="bonus-container">
         {canClaim ? (
      <button className="claim-bonus-btn" onClick={handleClaimBonus}>Claim Bonus</button>
    ) : (
      <p>Next claim available in<br /> <span className="time">{formatTime(timeLeft)}</span></p>
    )}
<br/>
<br/>
    <h3>Your Streak</h3>
    <div className="streak-grid">
      {[...Array(12).keys()].map(day => (
        <div key={day} className={`streak-item ${streak > day ? 'achieved' : ''} ${streak === day + 1 ? 'current' : ''}`}>
 
          <span>Day {day + 1}</span><br />
          <span className="rw">
            {calculateReward(day + 1)} HEXA
          
          </span>
        </div>
      ))}
    </div>
 
  </div>
  
  );
};

export default DailyBonus;
