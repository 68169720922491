import React, { useState } from 'react';
import { auth } from '../../components/utils/Firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const firebaseErrorMessages = {
    'auth/user-not-found': 'User not found. Please check your email address.',
    'auth/invalid-email': 'Invalid email address. Please provide a valid email.',
    // Add more error codes and messages as needed
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent successfully. Please check your email.');
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = firebaseErrorMessages[errorCode] || 'An error occurred. Please try again later.';
      setError(errorMessage);
    }
  };

  return (
    <div className="container">
      <h2>Reset Password</h2>
      <form onSubmit={handlePasswordReset}>
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <button   style={{width:"100%", margin:"0 auto", display:"block"}}>Reset Password</button>
      </form>
      {message && <p className="text-success mt-3">{message}</p>}
      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
};

export default PasswordReset;
