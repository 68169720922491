import React from 'react'; // Assuming you will create this CSS file for styling
import { BiX } from 'react-icons/bi';
import { FaDiscord, FaFacebook, FaInstagram, FaReddit, FaTelegram, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ContactUs from './ContactUs';
import Twitter from "../assets/images/twitter.png"

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>About Us</h4>
          <p>Learn more about our values.
            <br/> <Link to={`/about`}>Read more</Link></p>
        </div>
        <br/>
        <div className="footer-section">
      <ContactUs/>
      </div>
      <br/>
   
        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="footer-socials">
            <a className='twitter' href="https://t.me/hexalinkprotocol" target="_blank" rel="noopener noreferrer">
             <FaTelegram/>
            </a>  <a className='twitter' href="https://github.com/0xhexalink" target="_blank" rel="noopener noreferrer">
             <FaDiscord/>
            </a>
            <a className='twitter' href="https://reddit.com/u/hexalink_official" target="_blank" rel="noopener noreferrer">
             <FaReddit/>
            </a> 
             <a className='twitter' href="https://instagram.com/0xhexalink" target="_blank" rel="noopener noreferrer">
             <FaInstagram/>
            </a>
            <a href="https://x.com/0xhexalink" target="_blank" rel="noopener noreferrer">
          <img className='twitter'  src={Twitter} alt="" />
            </a>
          
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} HexaLink.org. All rights reserved.</p>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <br/>
    </footer>
  );
}

export default Footer;
