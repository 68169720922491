import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc, deleteDoc, increment, Timestamp } from 'firebase/firestore';
import box from '../assets/images/box.png';
import { useAuth } from '../hooks/Context'; // Adjust the import path to where you have your useAuth hook
import { db } from '../components/utils/Firebase';
import './ReferralContest.css';
import { FaPen } from 'react-icons/fa';

const ReferralContest = () => {
  const { currentUser, isAdmin, userData } = useAuth();
  const [isTaskFormVisible, setTaskFormVisible] = useState(false);
  const [contest, setContest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [claimMessage, setClaimMessage] = useState("");
  const [newContestData, setNewContestData] = useState({
    title: '',
    description: '',
    target: '',
    reward: ''
  });
  const [timeLeft, setTimeLeft] = useState(null);
  const [hasClaimedReward, setHasClaimedReward] = useState(false);

  useEffect(() => {
    if (currentUser) {
      getContestDetails();
    }
  }, [currentUser]);

  useEffect(() => {
    if (contest && contest.creationDate) {
      const interval = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [contest]);

  const getContestDetails = async () => {
    try {
      const contestDocRef = doc(db, 'contests', 'activeContest');
      const contestDoc = await getDoc(contestDocRef);
      if (contestDoc.exists()) {
        setContest(contestDoc.data());
      } else {
        setContest(null); // No contest found
      }
    } catch (error) {
      console.error('Error fetching contest details:', error);
    }
  };

  const claimReward = async () => {
    setClaimMessage("Verifying...");
    setLoading(true);

    setTimeout(async () => {
      if (userData.referralCount >= contest.target) {
        setClaimMessage("Claiming...");

        setTimeout(async () => {
          try {
            const userDocRef = doc(db, 'users', currentUser.uid);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              const newFundingBalance = parseFloat(contest.reward);
              await updateDoc(userDocRef, { balance: increment(newFundingBalance), hasClaimedReward: true });
              setHasClaimedReward(true);
              setClaimMessage("Congratulations! You’ve claimed your reward.");
            } else {
              setClaimMessage("Error: User not found.");
            }
          } catch (error) {
            console.error('Error claiming reward:', error);
            setClaimMessage("Error claiming reward. Please try again later.");
          } finally {
            setLoading(false);
          }
        }, 3000);
      } else {
        setClaimMessage("Oops, you need more referrals to claim the reward. Keep inviting friends!");
        setLoading(false);
      }
    }, 3000);
  };

  const createContest = async () => {
    try {
      const contestDocRef = doc(db, 'contests', 'activeContest');
      await setDoc(contestDocRef, {
        ...newContestData,
        status: 'active',
        creationDate: Timestamp.now()
      });

      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userDocRef, { hasClaimedReward: false });
      }

      getContestDetails();
    } catch (error) {
      console.error('Error creating contest:', error);
    }
  };

  const updateContest = async (newData) => {
    try {
      const contestDocRef = doc(db, 'contests', 'activeContest');
      await updateDoc(contestDocRef, newData);
      getContestDetails();
    } catch (error) {
      console.error('Error updating contest:', error);
    }
  };


  const markContestAsCompleted = async () => {
    await updateContest({ status: 'completed' });
  };

  const deleteCompletedContest = async () => {
    try {
      const contestDocRef = doc(db, 'contests', 'activeContest');
      await deleteDoc(contestDocRef);
      setContest(null);
    } catch (error) {
      console.error('Error deleting contest:', error);
    }
  };

  const formatReward = (reward) => {
    if (!reward) return '';
    return new Intl.NumberFormat().format(reward);
  };

  const calculateTimeLeft = () => {
    if (!contest || !contest.creationDate) return;

    const contestEndDate = contest.creationDate.toDate().getTime() + 30 * 24 * 60 * 60 * 1000;
    const now = new Date().getTime();
    const timeDifference = contestEndDate - now;

    if (timeDifference <= 0) {
      setTimeLeft(null);
      updateContest({ status: 'completed' });
    } else {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      setTimeLeft({ days, hours, minutes, seconds });
    }
  };

  return (
    <div className="referral-contest">
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <div className="loader"></div>
          {claimMessage}
        </div>
      ) : (
        <>
          {contest ? (
            <>
              <h2 style={{width:'100%', textAlign:"center"}}>{contest.title || 'Referral Contest'}</h2>
              <p className='ref'>{contest.description || 'Refer friends and claim rewards!'}</p>
              <div className="contest-info">
                <p className='target'>Invite {contest.target || 'N/A'} Friends</p>
                <p className='reward'><i> GET {formatReward(contest.reward) || 'N/A'} HEXA REWARD</i></p>
                <div className='contest-img'>
                  <img src={box} alt="Contest" />
                </div>
                <div className='cont'>
                  {contest.creationDate && contest.status !== "completed" && (
                    <>
                   {timeLeft && (
                        <div className="countdown">
                          <p className='con'>Time Left <br/> {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s</p>
                        </div>
                      )}
                  
                    </>
                  )}
                </div>
                {currentUser && (
                  <>
                    {contest.status === 'completed' ? (
                      <button className="claim-button completed" disabled>Completed</button>
                    ) : hasClaimedReward ? (
                      <button className="claim-button claimed" disabled>Claimed</button>
                    ) : (
                      userData.referralCount >= contest.target ? (
                        <button className="claim-button" onClick={claimReward}>Claim Reward</button>
                      ) : (
                        <p className="encouragement">You need {contest.target - userData.referralCount} more referrals to claim the reward.</p>
                      )
                    )}
                  </>
                )}
              </div>

                 {/* Floating Pen Icon for Admins */}
      {isAdmin && (
        <button
          className="floating-pen"
          onClick={() => setTaskFormVisible(true)}
          style={{
            position: 'fixed',
            bottom: '150px',
            right: '20px',
            backgroundColor: '#333',
            color: '#fff',
            border: 'none',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            fontSize: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        >
          <FaPen />
        </button>
      )}

{isTaskFormVisible && (
                <div className="admin-controls">
                  <input
                    type="text"
                    placeholder="Contest Title"
                    value={newContestData.title}
                    onChange={(e) => setNewContestData((prev) => ({ ...prev, title: e.target.value }))}
                  />
                  <input
                    type="text"
                    placeholder="Contest Description"
                    value={newContestData.description}
                    onChange={(e) => setNewContestData((prev) => ({ ...prev, description: e.target.value }))}
                  />
                  <input
                    type="number"
                    placeholder="Set Referral Target"
                    value={newContestData.target}
                    onChange={(e) => setNewContestData((prev) => ({ ...prev, target: e.target.value }))}
                  />
                  <input
                    type="number"
                    placeholder="Set Reward Amount"
                    value={newContestData.reward}
                    onChange={(e) => setNewContestData((prev) => ({ ...prev, reward: e.target.value }))}
                  />
                  <button onClick={createContest}>Create Contest</button>
                  <button onClick={() => setTaskFormVisible(false)}>Close</button>
                </div>
              )}
            </>

            
          ) : (
            <>
              <p>No active contest at the moment.</p>
        
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ReferralContest;
