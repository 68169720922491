import React from 'react';
import { FaTasks } from 'react-icons/fa';
import './TaskCenterIcon.css'; // Updated CSS file path for badge styling

const TaskCenterIcon = () => {
  return (
    <div className="task-center-icon">
      <FaTasks className="icon" />
      <span className="badge">!</span> {/* Red badge */}
    </div>
  );
};

export default TaskCenterIcon;
