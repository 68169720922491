import React from 'react';
import { Link } from 'react-router-dom';
import img from "../assets/images/hexa.jpg";
import { FaHome, FaChartLine, FaExchangeAlt, FaUser, FaWallet, FaGifts, FaTasks, FaTrophy, FaSpeakerDeck, FaBullhorn, FaUserFriends, FaTractor, FaDocker, FaAndroid } from 'react-icons/fa';
import Roadmap from './Roadmap';
import home from '../assets/images/home.png'
import blockchain from "../assets/images/mobile.png"
import consensus from '../assets/images/consensus.png'
import perform from '../assets/images/computer-monitor-with-keyboard-mouse-it_995828-9657_prev_ui.png'
import game from "../assets/images/gamebot.png"
import coin from "../assets/images/coin.png"
import nft from '../assets/images/pngwing.com (30).png'
import contract from '../assets/images/pngwing.com (44).png'
import defi from '../assets/images/pdefi.png'
function Home() {
  return (
    <div>
      <div className="nft-container home-container">
        <div className="nft-images">
          <img src={img} alt="" />
          {/* Insert your images here */}
          {/* You can use img tags or background images */}
        </div>
        <div className="nft-content">
          <h1>Welcome to HexaLink</h1>
          <h1>DeFi + GameFi</h1>
          <p>The Future of Web3 and Gaming</p>
          <p><i>(Sign up to Farm $HEXA)</i></p>
          <Link to="/sign-up">
            <button style={{ margin: "0 auto", borderTopLeftRadius: "20px", borderBottomRightRadius: "20px" }}>Sign Up</button>
          </Link>
          <br />
     
        </div>
        <br />
      </div>

      <div className="home">

        <section className='intro'>
          <div className="introduction">
            <div>
              <img src={home} alt="" />
            </div>
            <div>
              <h2>Introduction</h2>
              <p>
                HexaLink is an ambitious crypto project powered by the HEXA token, designed to create a hybrid ecosystem that seamlessly integrates decentralized finance (DeFi) and GameFi. Our mission is to provide a superior Web3 experience through a comprehensive mobile app, utilizing the advanced capabilities of the HexaLink blockchain.
              </p>
              <Link to={`/about`}>
                <button>Learn More</button>
              </Link>
            </div>

          </div>
          <br />
          <div className='home-grid2'>
            <div className='group'>
              <Link className='linkstyle' to={`/defi`}>
              <div className='img'>
<img src={defi} alt="" />
</div>
<p>     <strong>DeFi Integration</strong>
                HexaLink brings the power of Decentralized Finance (DeFi) directly into its ecosystem.
</p>
             
              </Link>
            </div>
            <div className='group'>
              <Link className='linkstyle' to={`/HEXA`}>
              <div className='img'>
              <img src={coin} alt="" />
              </div>

              <p> <strong>Get HEXA</strong>
              HEXA is the native utility token of the HexaLink ecosystem.</p>
               
              </Link>
            </div>
            <div className='group'>
              <Link className='linkstyle' to={`/nft&gaming`}>
              <div className='img'>
<img src={nft} alt="" />
</div>
<p> <strong>NFTs and Gaming</strong>
                The HexaLink ecosystem supports a vibrant NFT marketplace and gaming environment. 
</p>
            </Link>    
            </div>
            <div className='group'>
              <Link className='linkstyle' to={`/smart-contracts`}>
              <div className='img'>
              <img src={contract} alt="" />
</div>
              <p>   <strong>Smart Contracts</strong>
                HexaLink leverages advanced smart contract capabilities to enable complex and automated transactions.
</p>
             
              </Link>
            </div>
        
          </div>

        </section>

        <section className="challenges">
        <img  className="mobile" src={blockchain} alt="" />
          <div>
            <h2>Challenges in Mobile Blockchain Adoption</h2>
            <p>
              Despite the fact that some blockchains have made strides in addressing high gas fees, scalability, and interoperability, we are still not fully in the blockchain era. Many users encounter difficulties interacting with Web3 and decentralized applications (dApps), which can create barriers to adoption.
            </p>
            <p>
              HexaLink addresses these challenges by offering a more natural and seamless integration of blockchain technology into mobile applications. Our blockchain provides a Web3 experience that feels intuitive and hassle-free, allowing users to engage with blockchain technology without the traditional complexities making the DeFi space open to all.
            </p>
          </div>
          <div>
            <img className='desktop' src={blockchain} alt="" />
          </div>
        </section>

        <section className="gaming">

          <div>
            <img src={game} alt="" />
          </div>
          <div>
            <h2>Superior Gaming Experience</h2>
            <p>
              HexaLink bridges the gap between blockchain and traditional gaming by ensuring seamless integration, real-time updates, and enhanced security. This means that players can enjoy the benefits of blockchain technology, such as true ownership of in-game assets and NFTs, without compromising on the gaming experience. HexaLink makes blockchain-based games as smooth and engaging as traditional games, offering a superior experience to gamers.
            </p>
          </div>

        </section>



        <section className="performance">
        <img className="mobile" src={perform} alt="" />
          <div>
            <h2>Performance Hype: The Power of HexaLink Blockchain</h2>
            <p>
              HexaLink offers batch transactions and a block time of just 1 second. Batch transactions merge thousands of individual transactions into a single batch, which is processed together, significantly reducing network congestion and increasing efficiency. The 1-second block time ensures that transactions are processed almost instantly, providing a fast and seamless experience for users. This is particularly beneficial for gaming, where real-time interactions are crucial for an immersive experience.
            </p>
            <Link to={`/hexachain`}>
                <button>Learn More</button>
              </Link>
          </div> <div>
            <img className="desktop" src={perform} alt="" />
          </div>
        </section>



        <section className="consensus"> <div>
          <img src={consensus} alt="" />
        </div>
          <div>
            <h2>Consensus Mechanism and Block Finality</h2>
            <p>
              HexaLink utilizes a Proof-of-Stake (PoS) consensus mechanism, which is energy-efficient and promotes decentralization. Validators are chosen based on the amount of HEXA tokens they hold and are willing to 'stake' as collateral. This method reduces energy consumption compared to Proof-of-Work (PoW) systems and encourages active participation from the community. Blocks are finalized quickly, ensuring that transactions are confirmed and immutable in a short period, which is essential for maintaining trust and security within the network.
            </p>
          </div>
         
        </section>

        <section className="future">
          <h2>The Future of GameFi</h2>
          <p>
            HexaLink is redefining what's possible in GameFi, setting a new standard for blockchain performance, security, and integration. Join us as we bring the future of Web3 and gaming to the palm of your hand. With HexaLink, you can expect innovative features, a strong community, and a commitment to continuous improvement.
          </p>
        </section>
      </div>
    </div>
  );
}

export default Home;
