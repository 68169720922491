import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const technologies = [
  {
    title: 'HexaLink Blockchain',
    description: 'A custom-built blockchain with a Proof-of-Stake (PoS) consensus mechanism. It provides high throughput, low transaction fees, and minimal block time to ensure fast and efficient transactions.',
    image: '/images/blockchain.png',
  },
  {
    title: 'Substrate Framework',
    description: 'The blockchain is developed using the Substrate framework, which allows for high customization and scalability. It enables the creation of custom pallets and seamless integration with DeFi and GameFi functionalities.',
    image: '/images/substrate.png',
  },
  {
    title: 'Smart Contracts',
    description: 'Smart contracts on HexaLink are written in Ink! and are responsible for automating complex processes in DeFi and GameFi applications. They ensure transparency, security, and trustless operations.',
    image: '/images/smart-contracts.png',
  },
  {
    title: 'Interoperability Solutions',
    description: 'HexaLink supports cross-chain asset transfers and integration with other blockchains, enhancing its utility across different ecosystems and enabling seamless interaction between various DeFi and GameFi platforms.',
    image: '/images/interoperability.png',
  },
  {
    title: 'EVM Compatibility',
    description: 'HexaLink is compatible with the Ethereum Virtual Machine (EVM), allowing developers to deploy Ethereum-based smart contracts and dApps on the HexaLink blockchain and enhances interoperability.',
    image: '/images/evm-compatibility.png',
  },
  {
    title: 'Web3 Integration',
    description: 'The ecosystem is designed to integrate with Web3 technologies, providing a user-friendly interface for interacting with decentralized applications (dApps) and blockchain-based services.',
    image: '/images/web3.png',
  },
  {
    title: 'Oracles',
    description: 'Oracles are used to integrate real-world data into the blockchain, enabling smart contracts to interact with off-chain information and providing accurate data.',
    image: '/images/oracles.png',
  },
  {
    title: 'Cross-Chain Bridges',
    description: 'Cross-chain bridges facilitate the transfer of assets between different blockchains, ensuring liquidity and interoperability across various blockchain networks.',
    image: '/images/cross-chain.png',
  },
  {
    title: 'Layer 2 Solutions',
    description: 'Layer 2 scaling solutions are implemented to enhance transaction speeds and reduce costs, allowing for a more efficient and scalable ecosystem.',
    image: '/images/layer2.png',
  },
];

const TechnologyPage = () => {
  return (
    <Container className="my-5">
        <br />
        <br />
      <h1 className="text-center mb-4">HexaLink Core Technology</h1>
      <Row>
        {technologies.map((tech, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card className="card-dark">
           
              <Card.Body>
                <Card.Title>{tech.title}</Card.Title>
                <Card.Text>{tech.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

    </Container>
  );
};

export default TechnologyPage;
