import React from 'react';
import './Roadmap.css'; // Make sure to create this CSS file or include styles directly in your component

const Roadmap = () => {
  const phases = [
    {
      title: "Hexa Farming",
      steps: [
        {
          title: "Initial Farming Launch",
          description: "The Hexa Farming phase introduces users to the farming mechanics. Users can start earning HEXA tokens by participating in farming activities, which helps grow our community strength and rewards the community.",
          roles: ["Early Adopters", "Farmers", "Community Members"]
        },
        {
          title: "Farming Rewards Integration",
          description: "Integration of farming rewards ensures users are incentivized based on their participation. This step is crucial for maintaining engagement and attracting more users to the farming ecosystem.",
          roles: ["Farmers", "Investors"]
        },
        {
          title: "Hexa Premarket Trading",
          description: "Users can sell farmed Hexacoin points in the premarket, providing a new opportunity for liquidity and trading before the official market launch.",
          roles: ["Farmers", "Traders", "Investors"]
        }
      ]
    },
    {
      title: "Private Sale",
      steps: [
        {
          title: "Strategic Partnerships",
          description: "Engaging with strategic partners to secure investments and collaborations. This phase focuses on establishing important relationships to drive project success.",
          roles: ["Strategic Partners", "Investors"]
        },
        {
          title: "Investor Incentives",
          description: "Providing special incentives to private sale participants. This aims to reward early supporters and attract more high-value investors.",
          roles: ["Investors", "Advisors"]
        }
      ]
    },
    {
      title: "Presale",
      steps: [
        {
          title: "Token Distribution",
          description: "Initial distribution of tokens to early investors. This phase aims to generate initial interest and provide funds for development.",
          roles: ["Early Investors", "Community Supporters"]
        },
        {
          title: "Marketing and Outreach",
          description: "Campaigns to promote the presale and attract more participants. It involves engaging with potential investors and creating buzz around the project.",
          roles: ["Marketing Team", "Community Managers"]
        }
      ]
    },
    {
      title: "Airdrop",
      steps: [
        {
          title: "Initial Token Distribution",
          description: "The Airdrop phase marks the distribution of initial tokens to early supporters. This phase is crucial for building initial traction and engaging the community.",
          roles: ["Early Adopters", "Community Members"]
        }
      ]
    },
    {
      title: "Token Integrations",
      steps: [
        {
          title: "ERC-20 Token Integration",
          description: "This integration enables the creation and management of the native utility token for the metaverse. It facilitates transactions, rewards, and staking, enhancing liquidity and providing a robust economic backbone for the ecosystem.",
          roles: ["Developers", "Investors", "Traders"]
        },
        {
          title: "ERC-721 & ERC-1155 Token Integrations",
          description: "These integrations allow the creation of non-fungible tokens (NFTs) that represent unique in-game assets, collectibles, and items. This promotes asset ownership, trade, and interoperability within and across different games and platforms.",
          roles: ["Game Developers", "Collectors", "Gamers"]
        }
      ]
    },
    {
      title: "Governance Integrations",
      steps: [
        {
          title: "DAO Integration",
          description: "Decentralized Autonomous Organization (DAO) integration empowers the community to participate in decision-making processes. It ensures transparency, decentralization, and community-driven development, fostering trust and engagement.",
          roles: ["Community Members", "Governance Participants"]
        },
        {
          title: "Voting Integration",
          description: "This integration manages the voting mechanisms within the DAO, enabling token holders to vote on proposals and changes. It supports fair and democratic governance, enhancing the legitimacy of decisions made within the ecosystem.",
          roles: ["Token Holders", "Governance Participants"]
        }
      ]
    },
    {
      title: "Staking Integrations",
      steps: [
        {
          title: "Staking Rewards Integration",
          description: "Enables users to stake their tokens and earn rewards, providing incentives for holding tokens and contributing to network security and stability. It also encourages long-term investment in the ecosystem.",
          roles: ["Stakers", "Investors"]
        },
        {
          title: "Liquidity Mining Integration",
          description: "Incentivizes users to provide liquidity to token pools, which is essential for maintaining healthy trading volumes and reducing price volatility. This promotes a more stable and reliable market environment.",
          roles: ["Liquidity Providers", "Traders"]
        }
      ]
    },
    {
      title: "Marketplace Integrations",
      steps: [
        {
          title: "NFT Marketplace Integration",
          description: "Facilitates the buying, selling, and auctioning of NFTs. It creates a vibrant market for in-game assets, enabling players to monetize their assets and fostering a thriving economy within the metaverse.",
          roles: ["Buyers", "Sellers", "Auctioneers"]
        },
        {
          title: "Asset Rental Integration",
          description: "Allows users to rent in-game assets and properties, providing opportunities for players to generate passive income from their holdings and enabling others to access high-value items without outright purchase.",
          roles: ["Asset Owners", "Renters"]
        }
      ]
    },
    {
      title: "DeFi Integrations",
      steps: [
        {
          title: "Yield Farming Integration",
          description: "Allows users to stake tokens in various pools to earn yield. This enhances the financial ecosystem by providing diverse investment opportunities and increasing the overall token utility.",
          roles: ["Yield Farmers", "Investors"]
        },
        {
          title: "Lending and Borrowing Integration",
          description: "Facilitates decentralized lending and borrowing of tokens and assets. It provides liquidity to the market, enables leverage for trading, and supports financial inclusion by allowing users to borrow against their holdings.",
          roles: ["Lenders", "Borrowers"]
        }
      ]
    },
    {
      title: "Gaming Integrations",
      steps: [
        {
          title: "Game Logic Integration",
          description: "Manages the rules, mechanics, and outcomes of games. This integration ensures fair play and consistency across the gaming ecosystem, enhancing player trust and satisfaction.",
          roles: ["Game Developers", "Gamers"]
        },
        {
          title: "In-Game Economy Integration",
          description: "Oversees in-game currency, trading, and economic activities. It supports a dynamic and balanced in-game economy, promoting player engagement and long-term sustainability.",
          roles: ["Gamers", "Game Economists"]
        }
      ]
    },
    {
      title: "Cross-Chain Integrations",
      steps: [
        {
          title: "Bridge Integration",
          description: "Facilitates the transfer of tokens and assets between different blockchains. This integration enhances interoperability, allowing users to move assets seamlessly across various blockchain networks.",
          roles: ["Developers", "Investors"]
        }
      ]
    },
    {
      title: "Oracle Integrations",
      steps: [
        {
          title: "Price Oracle Integration",
          description: "Fetches real-time price data for various assets. It ensures accurate and up-to-date pricing information, which is critical for trading, lending, and other financial activities within the metaverse.",
          roles: ["Traders", "Investors"]
        },
        {
          title: "Data Oracle Integration",
          description: "Integrates real-world data into the metaverse. This allows for more dynamic and responsive game environments, connecting in-game events with real-world occurrences.",
          roles: ["Game Developers", "Data Analysts"]
        }
      ]
    },
    {
      title: "User Identity Integrations",
      steps: [
        {
          title: "Identity Verification Integration",
          description: "Manages the verification and management of user identities within the metaverse. It enhances security, prevents fraud, and supports personalized user experiences.",
          roles: ["Users", "Security Teams"]
        },
        {
          title: "Reputation System Integration",
          description: "Tracks and rewards user reputation and achievements. This fosters a positive community environment, incentivizing good behavior and rewarding contributions.",
          roles: ["Gamers", "Community Managers"]
        }
      ]
    },
    {
      title: "Auction Integrations",
      steps: [
        {
          title: "English Auction Integration",
          description: "Supports standard auctioning of items. This integration creates competitive bidding environments, ensuring fair market value for high-demand items.",
          roles: ["Auction Participants", "Sellers"]
        },
        {
          title: "Dutch Auction Integration",
          description: "Facilitates auctions where the price decreases until a buyer is found. This is particularly useful for selling time-sensitive or perishable items.",
          roles: ["Auction Participants", "Sellers"]
        }
      ]
    },
    {
      title: "In-Game Integrations",
      steps: [
        {
          title: "Loot Box Integration",
          description: "Randomizes rewards in loot boxes. This integration adds excitement and unpredictability to gameplay, enhancing player engagement and retention.",
          roles: ["Gamers", "Developers"]
        },
        {
          title: "Quest and Achievement Integration",
          description: "Manages in-game quests and achievements. It provides structured goals and rewards for players, enhancing the overall gaming experience and encouraging continued participation.",
          roles: ["Gamers", "Developers"]
        }
      ]
    },
    {
      title: "Subscription Integrations",
      steps: [
        {
          title: "Subscription Service Integration",
          description: "Manages subscriptions to in-game services and features. It creates recurring revenue streams for the ecosystem and provides premium experiences for subscribers.",
          roles: ["Subscribers", "Service Providers"]
        }
      ]
    },
    {
      title: "Advertising Integrations",
      steps: [
        {
          title: "Ad Space Integration",
          description: "Facilitates buying and selling of virtual ad space within the metaverse. This integration supports monetization efforts and provides targeted advertising opportunities.",
          roles: ["Advertisers", "Property Owners"]
        }
      ]
    },
    {
      title: "Property Management Integrations",
      steps: [
        {
          title: "Land Ownership Integration",
          description: "Manages ownership and trade of virtual land. This integration enables users to buy, sell, and develop virtual real estate within the metaverse.",
          roles: ["Land Owners", "Developers"]
        },
        {
          title: "Building and Customization Integration",
          description: "Supports the building and customization of virtual properties. This allows users to create unique spaces and enhance their in-game presence.",
          roles: ["Property Owners", "Designers"]
        }
      ]
    },
    {
      title: "Social Interactions Integrations",
      steps: [
        {
          title: "In-Game Chat Integration",
          description: "Facilitates real-time communication between players. This integration enhances social interactions and community building within the metaverse.",
          roles: ["Gamers", "Community Managers"]
        },
        {
          title: "Friends and Guilds Integration",
          description: "Manages friendships and guilds within the metaverse. It supports social connections and group activities, fostering a sense of community.",
          roles: ["Gamers", "Community Managers"]
        }
      ]
    },
    {
      title: "User Experience Integrations",
      steps: [
        {
          title: "Customizable Avatars Integration",
          description: "Enables users to create and customize their avatars. This adds a personal touch to user interactions within the metaverse, promoting self-expression and individuality.",
          roles: ["Gamers", "Developers"]
        },
        {
          title: "Interactive Environments Integration",
          description: "Creates dynamic and responsive virtual environments. This enhances the immersive experience, making interactions more engaging and realistic.",
          roles: ["Gamers", "Developers"]
        }
      ]
    }
  ];

  return (
    <div className="roadmap-container">
      <h1>Development Roadmap</h1>
      {phases.map((phase, phaseIndex) => (
        <div key={phaseIndex} className="phase">
          <div className="phase-circle"></div>
          <div className="phase-content">
            <h2 className="phase-title">{phase.title}</h2>
            {phase.steps.map((step, stepIndex) => (
              <div key={stepIndex} className="step">
                <h3 className="step-title">{step.title}</h3>
                <p className="description">{step.description}</p>
                <p className="user-role">
                  <strong>User Roles:</strong> {step.roles.join(', ')}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Roadmap;


