// src/components/Navbar.js
import React from 'react';
import { FaBell, FaUserCircle } from 'react-icons/fa';
import './Navbar.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import BellIcon from '../utils/BellIcons';

const Navbar = () => {
  const navigate=useNavigate();
  return (
    <div className="navbar">
   

      <div className="logo">HexaLink</div>
      <div className="notification">
      <FaUserCircle onClick={()=>navigate("/profile")} />
      </div>
    </div>
  );
};

export default Navbar;
