// src/components/AdminTaskCenter.js

import React, { useState, useEffect } from 'react';
import { db } from '../components/utils/Firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/Context';

const AdminTaskCenter = () => {
  const [title, setTitle] = useState('');
  const [points, setPoints] = useState('');
  const [link, setLink] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { currentUser, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else if (!isAdmin) {
      navigate('/'); // Redirect non-admin users to the home page
    }
  }, [currentUser, isAdmin, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      navigate('/login');
      return;
    }

    if (!title || !points || !link) {
      setError('All fields are required');
      return;
    }

    try {
      const newTask = {
        title,
        points: parseInt(points, 10),
        link, // Add the link to the task data
        Completed: false, // Set Completed to false
      };

      await addDoc(collection(db, 'tasks'), newTask);

      setTitle('');
      setPoints('');
      setLink('');
      setSuccess('Task created successfully!');
      setError('');
    } catch (error) {
      console.error('Error creating task: ', error);
      setError('Failed to create task');
      setSuccess('');
    }
  };

  if (!isAdmin) {
    return <p>You do not have access to this page.</p>;
  }

  return (
    <div className="container">
      <h3>Admin Task Center</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Task Title"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="number"
            className="form-control"
            value={points}
            onChange={(e) => setPoints(e.target.value)}
            placeholder="Points"
            required
          />
        </div>
        <div className="mb-3">
          <input
            type="url"
            className="form-control"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Link"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Create Task</button>
      </form>
      {error && <p className="text-danger mt-3">{error}</p>}
      {success && <p className="text-success mt-3">{success}</p>}
    </div>
  );
};

export default AdminTaskCenter;
