import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../utils/Firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Loader from '../utils/Loader';
// Import the Loader component

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const firebaseErrorMessages = {
    'auth/invalid-email': 'Invalid email address.',
    'auth/user-disabled': 'This user has been disabled.',
    'auth/user-not-found': 'No user found with this email.',
    'auth/wrong-password': 'Incorrect password.',
    // Add more error codes and messages as needed
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true when login starts

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // Navigate to the dashboard page
    } catch (error) {
      const errorMessage = firebaseErrorMessages[error.code] || 'An error occurred. Please try again.';
      setError(errorMessage);
    } finally {
      setIsLoading(false); // Set loading state to false after login attempt
    }
  };

  return (
    <div className="signup">
     
      {isLoading ? ( // Conditionally render the loader or the form
        <Loader /> // Show loader when loading state is true
      ) : (
        <form onSubmit={handleLogin}>
           <h2><i>Welcome Back!</i></h2>
          <div className="mb-3">
            <input 
              type="email" 
              className="form-control"
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Email" 
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="password" 
              className="form-control"
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              required 
            />
          </div>
          <span>
            <button type="submit" style={{width:"100%", margin:"0 auto", display:"block"}}>Log in</button>
          </span>  
          <span> Don't have an account? <i><Link to="/sign-up">Sign up.</Link></i></span> 
          <span><Link to="/reset-password"> Forgot Password?</Link> </span>
        </form>
      )}
      {error && <p className="text-danger mt-3">{error}</p>}
    </div>
  );
}

export default SignIn;
