import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import GetStarted from './GetStarted';
import home from '../assets/images/home.png'
import house from '../assets/images/digital-marketer-system-administrator-data-illustrated-flat-minimal-design_987764-253895_prev_ui.png'
import about from '../assets/images/cartoon-boy-stage-with-words-game-it_1108514-70877.jpg'

const About = () => {
  return (
    <div>
    <img src={about} style={{borderBottom:'2px solid white'}} alt="" />
  <Container className="box">
  
    <br />
    <br />
    <Row className="mb-5">
    <Col md={12}>
            <Card className="card-dark">
              <Card.Body>
              <div className='imageb'>
              <img src={home} alt="" />
            </div>
            <br /><br />
                <Card.Title as="h3">
                 
                  DeFi and GameFi in a Unified Ecosystem for Mobile Users
                </Card.Title>
                <Card.Text>
                  HexaLink ($HEXA) stands as a pioneering force, seamlessly integrating the of Decentralized Finance (DeFi) and Game Finance (GameFi) into one cohesive hybrid ecosystem. Our mission is to democratize access to Web 3.0, making it intuitive and accessible for mobile users worldwide through fast and scalable solutions, thus accelerating public adoption of blockchain technology.
                </Card.Text>
              </Card.Body>
            </Card>
            
          </Col>
        </Row>

            <Row className="mb-5">
            <Col md={12}>

            <Card className="card-dark">
              <Card.Body>
              <div className='imaged'>
              <img src={house} alt="" />
            </div>
            <br />
            <br />
            <br />
                <Card.Title as="h3">
                  The Future of Finance and Gaming in One Ecosystem
                </Card.Title>
                <Card.Text>
                  HexaLink is not just a crypto project; it's a vision for the future where financial autonomy and gaming enjoyment coexist harmoniously. By merging DeFi and GameFi, HexaLink offers an unprecedented user experience where financial activities and gaming rewards are integrated, creating a dynamic and engaging environment for users.
                </Card.Text>
              </Card.Body>
            </Card>
            
          </Col>
        </Row>

        
<ul className='home-grid2'>
            <li>
            <img className='egg2' src={require('../assets/images/Layer 13_044241.png')} alt="" />
                <strong>
                  Driving Public Adoption of Web 3.0
                </strong>
                  HexaLink is dedicated to accelerating the mainstream adoption of Web 3.0 by providing a platform that is not only powerful and versatile but also accessible and easy to use. Our comprehensive ecosystem lowers the barriers to entry, enabling a broader audience to experience the benefits of blockchain technology.
                </li>

            <li>
            <img className='egg2' src={require('../assets/images/Layer 14_044239.png')} alt="" />
                <strong>
                  Farm Hexa Points
                </strong>
                  Join HexaLink farming through task completion, daily reward claims, and invite users using your referral code to share in the upcoming massive airdrop. Engage with our ecosystem, earn rewards, and become a part of the HexaLink community.
                </li>
                

       

            <li>
            <img className='egg2' src={require('../assets/images/Layer 5_044256.png')} alt="" />
            <strong>
                  Community and Partnerships
                </strong>
    
                  HexaLink is committed to building a thriving community and forming strategic partnerships to drive innovation and growth. Join our vibrant community on social media, participate in forums, and collaborate with partners to explore new opportunities and enhance the ecosystem.
                
      

            </li>
            <li>
            <img className='egg2' src={require('../assets/images/Layer 4_044258.png')} alt="" />
            <strong>                  Future Developments
                </strong>
                  We are constantly working on exciting developments to expand and enhance the HexaLink ecosystem. Stay tuned for upcoming features, integrations, and updates that will further enrich the user experience and expand our platform’s capabilities.
                
        </li>
        </ul>

            <Row className="mb-5">
            <Col md={12}>
            <Card className="card-dark">
              <Card.Body>
                <Card.Title as="h3" className='text-center'>
                  Fundamentals of HexaLink
                </Card.Title>
                <ul className="home-grid">
                  <li>
                    <strong>Unified DeFi and GameFi Platform</strong> HexaLink bridges the gap between DeFi and GameFi, allowing users to manage their finances and enjoy gaming experiences without needing to switch between different platforms. This integration simplifies user engagement and enhances overall usability.
                  </li>
                  <li>
                    <strong>Mobile-First Design</strong> Understanding the growing reliance on mobile devices, HexaLink is optimized for mobile access, ensuring that users can participate in the ecosystem anytime, anywhere. Our mobile-first approach ensures seamless navigation and interaction, catering to the on-the-go lifestyle of modern users.
                  </li>
                  <li>
                    <strong>User-Friendly Interface</strong> HexaLink is designed with a focus on user experience, offering an intuitive interface that caters to both beginners and experienced users. Our platform demystifies Web 3.0 technologies, making it easy for anyone to engage with decentralized applications and financial services.
                  </li>
                  <li>
                    <strong>Enhanced Security and Transparency</strong> Utilizing state-of-the-art blockchain technology, HexaLink ensures the highest level of security and transparency. Users can trust that their assets and data are protected by robust cryptographic protocols, fostering a secure and reliable environment.
                  </li>
                  <li>
                    <strong>Interoperability</strong> HexaLink supports interoperability with other blockchain networks, enhancing the flexibility and utility of the $HEXA token. This feature allows users to seamlessly transfer assets across different platforms, maximizing the potential use cases and benefits.
                  </li>
                  <li>
                    <strong>Decentralized Governance</strong> Empower the community to participate in the decision-making process through a decentralized governance model. HEXA holders have the power to vote on key protocol upgrades and changes, ensuring that the platform evolves in a way that reflects the interests of its users.
                  </li>
                  <li>
                    <strong>Rewards and Incentives</strong> Engage with the ecosystem through various reward mechanisms, including staking rewards, liquidity mining, and airdrops. HexaLink incentivizes user participation and contribution, creating a vibrant and active community.
                  </li>
                </ul>
              </Card.Body>
            </Card>
            
          </Col>
        </Row>
        

        <GetStarted/>
      </Container>
      </div>
    
  );
};

export default About;
