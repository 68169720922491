import Carousel from 'react-bootstrap/Carousel';

import img1 from "../assets/images/h6.jpg"
import img2 from "../assets/images/h5.jpg"
import img3 from "../assets/images/h4.jpg"
import img4 from "../assets/images/h3.jpg"


  import 'bootstrap/dist/css/bootstrap.min.css';


function Slider() {
  return (
    <Carousel controls={false} fade className='slider'>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={img3}
        alt="First slide"
      />
      <Carousel.Caption>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={img2}
        alt="Second slide"
      />
  
      <Carousel.Caption>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={img1}
        alt="Third slide"
      />
  
      <Carousel.Caption>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={img4}
        alt="Third slide"
      />
  
      <Carousel.Caption>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  );
}

export default Slider;