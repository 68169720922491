import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GetStarted from './GetStarted';
import coin from '../assets/images/coin.png'
import icon1 from '../assets/images/money.png'
import icon2 from '../assets/images/gamepad.png'
import icon3 from '../assets/images/dao.png'
import icon4 from '../assets/images/data.png'
import vault from '../assets/images/vault.png'
import fee from '../assets/images/fee.png'

import game from "../assets/images/Layer 17_044235.png"
// Ensure this import includes your CSS

const HexaCoinPage = () => {
  return (
    <Container className="my-5">
      <br />
      <br />
      <h1 className="text-center mb-4"></h1>

      {/* HEXA Overview */}
      <Row className="mb-5">
        <Col md={12}>
          <Card className="card-dark">
            <Card.Body>
              <img className="imageb" src={coin} alt="" />
              <br />
              <br />
              <Card.Title>What is Hexa Coin (HEXA)?</Card.Title>
              <Card.Text>
                Hexa Coin (HEXA) is the native cryptocurrency of the HexaLink ecosystem, designed to facilitate transactions and incentivize participation within our platform. HEXA powers both our decentralized finance (DeFi) and gaming functionalities, serving as the primary medium of exchange and value within the HexaLink network.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Tokenomics */}
      <Row className="mb-5">
        <Col md={12}>
          <Card className="card-dark">
            <Card.Body>
              <Card.Title>Tokenomics</Card.Title>
              <Card.Text>
                HEXA has a well-structured tokenomics model to ensure stability and growth:
              </Card.Text>
              <ul>
                <li><strong>Total Supply:</strong> 1,000,000,000 HEXA</li>
                <li><strong>Initial Circulating Supply:</strong> 100,000,000 HEXA</li>
                <li><strong>Distribution:</strong></li>
                <ul>
                  <li>40% - Community and Ecosystem Growth</li>
                  <li>20% - Team and Advisors</li>
                  <li>20% - Reserve Fund</li>
                  <li>10% - Partnerships and Strategic Investments</li>
                  <li>10% - Initial Token Sale</li>
                </ul>
                <li><strong>Utility:</strong> HEXA can be used for transaction fees, staking, participating in governance, and purchasing in-game assets and DeFi services within the HexaLink ecosystem.</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

     
              <ul className='home-grid'>
                <li>
                  <img src={fee} alt="" /><strong>Transaction Fees-</strong> Used to pay for transaction fees on the HexaLink blockchain.</li>
                <li>
                  <img src={vault} alt="" />
                  <strong>Staking</strong> Users can stake HEXA to earn rewards and participate in the network's security and governance.</li>
                <li>
                  <img src={icon3} alt="" /><strong>Governance</strong> HEXA holders have voting rights to influence the development and direction of the HexaLink ecosystem.</li>
                <li>
                  <img src={icon2} alt="" /><strong>In-Game Assets</strong> HEXA can be used to purchase, trade, and sell in-game assets and NFTs within the HexaLink gaming platform.</li>
                <li>
                  <img src={icon1} alt="" /><strong>DeFi Services</strong> Facilitates interactions with DeFi services such as liquidity pools, lending, and yield farming.</li>
              </ul>
      

    
     <GetStarted/>
    </Container>
  );
};

export default HexaCoinPage;
